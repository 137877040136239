import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Signup.module.scss";
import axios from 'axios';
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Col, Form } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";
import google from "../../../images/google.svg";
import facebook from "../../../images/facebook.svg";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { RotatingLines } from "react-loader-spinner";

export default function Signup() {
  let Id: any = useParams();
  const navigate = useHistory();
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Sign Up')

  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [fcmToken, setfcmToken] = useState('');
  // useEffect(() => {
  //   const msg: any = firebase?.messaging() ?? {};
  //   msg.requestPermission().then(() => {
  //     return msg.getToken();
  //   }).then((data: any) => {
  //     setfcmToken(data)
  //     // console.log(data, "token877878");
  //   })
  // })

  // -----------------------------SIGNUP API------------------------//
  function addstudentnew(postdata: any) {
    let formdata = new FormData();
    formdata.append('email', postdata.email);
    formdata.append('password', postdata.password);
    formdata.append('confirm_password', postdata.confirm_password);
    formdata.append('coupon_code', Id._id || postdata.coupon_code);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/register`,
      headers: {
        Authorization: "Bearer" + JSON.parse(localStorage.getItem("api_token")!),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/student/otp-verification',
              state: { detail: response.data.id, email: postdata.email, component: "signup" }
            });
          }, 2000);
        }
        else {
          setLoadingSpinner('Sign up')
          if (response.data.statusCode === 400 && response.data.data.student_complete_profile_status === 0)
            setTimeout(() => {
              navigate.push({
                pathname: '/student/complete-profile',
                state: { userId: response.data.data.id, email: response.data.data.email }
              });
            }, 3000);
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }

      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  // --------------------------LOGIN GOOGLE/FB API--------------------------//
  function loginstudentSSO(name: any, email: any, type: number) {
    // console.log(123456789)
    let formdata = new FormData();
    formdata.append('name', name);
    formdata.append('email', email);
    formdata.append('social_login_type', type.toString());
    formdata.append('usertype', "1");
    formdata.append('deviceToken', fcmToken)

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/social-signup`,
      Authorization: "Bearer" + localStorage.getItem("api_token"),
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        // console.log(response, "456789876543");
        // if (response.data.data.api_token) {
        if (response.data.statusCode == 200) {
          if (response.data.data.student_complete_profile_status == 0) {
            setTimeout(() => {
              navigate.push('/student/complete-profile', {
                userId: response.data.data.id,
                email: response.data.data.email
              });
            }, 3000);
            setTimenotify(response.data.message);
            setOnetimenotify(true)
          }
          else {
            localStorage.setItem("api_token", response.data.data.api_token);
            localStorage.setItem("userData", JSON.stringify(response.data.data));
            setTimenotify(response.data.message);
            setOnetimenotify(true);
            setTimeout(() => {
              navigate.push("/student/home");
            }, 3000);
          }
        }
        // }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        // notify(error?.response?.data?.msg);
        notify(error?.response?.data?.message);
      });
  }

  // function signInWithGoogle(e: any) {
  //   // firebase.auth().signInWithRedirect(provider);
  //   firebase.auth()
  //     .signInWithPopup(provider)
  //     .then((result) => {
  //       /** @type {firebase.auth.OAuthCredential} */
  //       let credential: any = result.credential;
  //       // This gives you a Google Access Token. You can use it to access the Google API.
  //       let token = credential.accessToken;
  //       // The signed-in user info.
  //       let user = result.user;
  //       // IdP data available in result.additionalUserInfo.profile.
  //       // ...
  //       loginstudentSSO(user?.displayName, user?.email, e);
  //     }).catch((error) => {
  //       // Handle Errors here.
  //       let errorCode = error.code;
  //       let errorMessage = error.message;
  //       // The email of the user's account used.
  //       let email = error.email;
  //       // The firebase.auth.AuthCredential type that was used.
  //       let credential = error.credential;
  //       // ...
  //     });
  // }

  const responseFacebook = (response: any) => {
    loginstudentSSO(response?.name, response?.email, 1)
  };

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <img src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img src={logo} className={`${cx.logo}`} />
            </Col>
          </div>

          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={'m-auto'}>
              <h5 className={`${st.themeColor}`}>Hello!</h5>
              <p className={`${st.fontColor}`}>Create an account</p>
            </Col>
            <Col lg={4} className={'m-auto'}>
              <Form onSubmit={handleSubmit(addstudentnew)} className={`${st.formFull}`}>
                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control type="email" placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    })}
                  />
                  {errors?.email?.type === "pattern" && (
                    <p style={{ color: "red" }}>Invalid email address</p>
                  )}
                  {errors?.email?.type === "required" && (
                    <p style={{ color: "red" }}>
                      This field is required
                    </p>
                  )}

                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <div className="position-relative">
                    <Form.Control type={show ? "password" : "text"} placeholder="Enter Password"

                      {...register("password", {
                        required: "This password is required",
                        minLength: {
                          value: 6,
                          message: "Please enter minimum 6 characters",
                        },
                      })}
                    />
                    {show ? (
                      <AiFillEyeInvisible
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow(false);
                        }}
                      />
                    ) : (
                      <AiFillEye
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow(true);
                        }}
                      />
                    )}

                  </div>


                  {errors?.password?.type === "minLength" && (
                    <p style={{ color: "red" }}>
                      Please enter minimum 6 characters
                    </p>
                  )}
                  {errors?.password?.type === "required" && (
                    <p style={{ color: "red" }}>This field is required</p>
                  )}
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <div className="position-relative">
                    <Form.Control type={show2 ? "password" : "text"} placeholder="Confirm Password"
                      {...register("confirm_password", {
                        required: "This password is required",
                        minLength: {
                          value: 6,
                          message: "Please enter minimum 6 characters",
                        },
                      })}
                    />
                    {show2 ? (
                      <AiFillEyeInvisible
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow2(false);
                        }}
                      />
                    ) : (
                      <AiFillEye
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow2(true);
                        }}
                      />
                    )}
                  </div>

                  {errors?.confirm_password?.type === "minLength" && (
                    <p style={{ color: "red" }}>
                      Please enter minimum 6 characters
                    </p>
                  )}
                  {errors?.confirm_password?.type === "required" && (
                    <p style={{ color: "red" }}>This field is required</p>
                  )}
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control type="text" placeholder="Referral Code" value={Id.id}
                    {...register("coupon_code", {
                      // required: true,
                      // pattern: /^[a-zA-Z ]+$/,
                      // min: 1,
                    })}
                  />
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    {/* <NavLink to="/student/otp-verification" className={`btn ${st.actionBtn}`}>
                      Sign Up
                    </NavLink> */}
                    <button type="submit" className={`btn ${st.actionBtn}`}>
                      {loadingSpinner}
                    </button>
                  </Col>
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    <p className={`${st.themeColor} text-center mb-0 mt-4`}>Or Continue with</p>
                    <ul className={`${cx.socialSignup}`}>
                      <li>
                        <NavLink to="#">
                          <img src={google}
                            onClick={() => {
                            }} />
                        </NavLink>
                      </li>
                      <li>
                        <span>
                          <FacebookLogin
                            appId="3445293595716613"
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={responseFacebook}
                            render={(renderProps) => (
                              <img onClick={renderProps.onClick} src={facebook} />
                            )} />
                        </span>
                      </li>
                    </ul>
                    <p className={`${st.fontColor} text-center mt-3`}>Already have an account? <NavLink className={`${st.themeColor} text-decoration-none`} to="/student/login">Login</NavLink></p>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
}