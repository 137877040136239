import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Changepassword.module.scss";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { NavLink, useHistory } from "react-router-dom";
import { Col, Row, Form, Button } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from "react-loader-spinner";

export default function Changepassword(props: any) {
  let localData = JSON.parse(localStorage.getItem("userData")!);
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);
  const [show3, setShow3] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Change Password')

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 1000);
    }
  }, [onetimenotify])

  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useHistory();
  const [current_password, setCurrent_password] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirm_password, setConfirm_password] = useState('');

  // -------------------CHANGE PASSWORD API---------------------//
  function changeStudentPassword() {

    let formdata = new FormData();
    formdata.append('userId', localData.id);
    formdata.append('current_password', current_password);
    formdata.append('newPassword', newPassword);
    formdata.append('confirm_password', confirm_password);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changePassword`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/student/profile',
              state: { detail: response.data.id }
            });
          }, 1000);
        }
        else {
          setLoadingSpinner('Change Password')
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify("Something went wrong");
      });
  }

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col>
                  <NavLink to="/student/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col>
                </Col>
                <Col className="text-end">
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img src={logo} className={`${cx.logo}`} />
            </Col>
          </div>

          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={'m-auto'}>
              <h5 className={`${st.themeColor}`}>Change Password</h5>
              <p className={`${st.fontColor}`}>Please enter your new password</p>
            </Col>
            <Col lg={4} className={'m-auto'}>
              <Form onSubmit={handleSubmit(changeStudentPassword)} className={`${st.formFull}`}>

                <Form.Group className={`${st.formField}`} controlId="">
                  <div className="position-relative">
                    <Form.Control type={show ? "password" : "text"} placeholder="Current Password"
                      value={localData.current_password}
                      key={props?.current_password}
                      onChange={(e) => {
                        setCurrent_password(e.target.value)
                      }}
                    />
                    {show ? (
                      <AiFillEyeInvisible
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow(false);
                        }}
                      />
                    ) : (
                      <AiFillEye
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow(true);
                        }}
                      />
                    )}
                    {errors?.current_password?.type === "minLength" && (
                      <p style={{ color: "red" }}>
                        Please enter minimum 6 characters
                      </p>
                    )}
                    {errors?.current_password?.type === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <div className="position-relative">
                    <Form.Control type={show2 ? "password" : "text"} placeholder="Enter New Password"
                      value={localData.newPassword}
                      key={props?.newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value)
                      }} />
                    {show2 ? (
                      <AiFillEyeInvisible
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow2(false);
                        }}
                      />
                    ) : (
                      <AiFillEye
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow2(true);
                        }}
                      />
                    )}
                    {errors?.newPassword?.type === "minLength" && (
                      <p style={{ color: "red" }}>
                        Please enter minimum 6 characters
                      </p>
                    )}
                    {errors?.newPassword?.type === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <div className="position-relative">
                    <Form.Control type={show3 ? "password" : "text"} placeholder="Confirm New Password"
                      value={localData.confirm_password}
                      key={props?.confirm_password}
                      onChange={(e) => {
                        setConfirm_password(e.target.value)
                      }} />
                    {show3 ? (
                      <AiFillEyeInvisible
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow3(false);
                        }}
                      />
                    ) : (
                      <AiFillEye
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow3(true);
                        }}
                      />
                    )}
                    {errors?.confirm_password?.type === "minLength" && (
                      <p style={{ color: "red" }}>
                        Please enter minimum 6 characters
                      </p>
                    )}
                    {errors?.confirm_password?.type === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    <Button className={`btn ${st.actionBtn}`} type="submit">
                      {loadingSpinner}
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
}