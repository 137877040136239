import { configureStore } from "@reduxjs/toolkit";
import chatCount from "../reducer/chatCount";
import notifyCount from "../reducer/notifyCount";
import classCount from "../reducer/classCount";

export const store = configureStore({
    reducer: {
        chatCount: chatCount,
        notifyCount: notifyCount,
        classCount: classCount
    },
});