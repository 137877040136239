import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    countState: 0,
    allID: 0,
}

const chatCount: any = createSlice({
    name: "chatCount",
    initialState,
    reducers: {
        changeChatCount(state = initialState, action) {
            // console.log(action, "acttionnn")
            state.countState = action?.payload?.length
        },
        allIDs(state = initialState, action) {
            // console.log(action, "teacherid7777776")
            state.allID = action?.payload
            // state.countState = action?.payload?.length
        },

    },
});
export const { changeChatCount, allIDs } = chatCount.actions;
export default chatCount.reducer;