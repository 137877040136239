import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Home.module.scss";
import axios from 'axios';
import defaultImg from "../../../images/defaultImg.png"
import { NavLink, useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { AiFillGift, AiFillMessage } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { MdNotifications } from "react-icons/md";
import { FaWallet } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Bottombar from "../../../components/Student/Bottombar/Bottombar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import ReactLoading from "react-loading";
import { query, collection, orderBy, limit, onSnapshot } from "@firebase/firestore";
import { Unsubscribe } from "@reduxjs/toolkit";
import { db } from "../../../firebase";

export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const options = {
    loop: true,
    items: 1,
    dots: false,
    autoplay: true
  };
  const options2 = {
    loop: false,
    items: 3,
    autoWidth: true,
    dots: false,
    autoplay: false
  };

  let localData = JSON.parse(localStorage.getItem("userData")!);

  // REMOVE TAGS (<p>/<a>/nbps;)
  function removeTags(str: any) {
    if ((str === null) || (str === ' ') || (str === undefined))
      return false;
    else
      str = str.toString();
    str = str.replace(/&nbsp;/g, '');
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  const navigate = useHistory();
  const [data, setData] = useState<any[]>([]);
  const [classOrCategory, setClassOrCategory] = useState<any>("");
  const [subject, setSubject] = useState<any>();
  const [image, setImage] = useState("");
  const [course_images, setCourse_images] = useState("");
  const [search, setSearch] = useState<any>("");
  const [forSearchData, setForSearchData] = useState<any>();
  const [notificatCount, setNotificationCount] = useState("");
  const [activeClass, setActiveClass] = useState<any>("");

  useEffect(() => {
    setData([...data])
  }, [activeClass])

  const [loaderStatus, setloaderStatus] = useState(<ReactLoading type="spin" color="blue" width='40px' />);

  // --------------------------------CLASS/CATEGORY API-----------------------------------//
  function getCategoryId(e: any) {
    setClassOrCategory(e);
    let formdata = new FormData();
    formdata.append('userId', localData?.id);
    formdata.append('type', "All");
    formdata.append('class_id', localData?.class_id);
    formdata.append('category_id', localData?.category_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/gethomeScreen`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token")!,
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        setNotificationCount(response.data.count);
        if (response.data.data) {
          setloaderStatus(<div> <br /> <br /> Subject Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          setData(response?.data?.data);

          let arrClsCat: any = [];
          // if (localData.category_id === null) {
          //   setSubject(response.data.data[0]?.category[0].course_Details)
          // }
          response?.data?.data?.[0]?.category?.map((e: any) => {
            if (localData.category_id && e.id == localData.category_id) {
              setSubject(e.course_Details)
            }
            e?.course_Details?.map((a: any) => {
              arrClsCat.push({
                type: "category_subject",
                name: e.name,
                data: a,
                imageUrl: `${response.data.data[0]?.course_images}/`
              })
            })
            arrClsCat.push({
              type: "category",
              data: e,
              imageUrl: response.data.data[0]?.category_images
            })
          })
          response.data.data[0]?.classes?.map((e: any) => {
            // console.log(e, "123456789023456789")
            if (e.id == localData.class_id) {
              setSubject(e.subject_Details)
            }
            e?.subject_Details?.map((a: any) => {
              arrClsCat.push({
                type: "class_subject",
                name: e.class_name,
                data: a, imageUrl: response.data.data[0]?.subject_images
              })
            })
            arrClsCat.push({
              type: "class",
              data: e,
              imageUrl: response.data.data[0]?.class_images
            })
          })
          response?.data?.data[0]?.demo_request?.map((e: any) => {
            arrClsCat.push({
              type: "demo_request",
              data: e,
            })
          })
          setForSearchData(arrClsCat);
          setImage(response.data?.data[0]?.subject_images);
          setCourse_images(response.data?.data[0]?.course_images);
        }
      })
      .catch(function (error) {
        notify("Something went wrong, Please logout and login again");
        console.log(error)
      });
  }

  const searchFilter = (item: any, type: String) => {
    if (type === "classes") {
      setClassOrCategory("classes");
      setSubject(item?.subject_Details);
    }
    else {
      setClassOrCategory("category");
      setSubject(item?.course_Details);
    }
  }

  useEffect(() => {
    if (localData?.category_id == null) {
      getCategoryId("classes");
    } else {
      getCategoryId('category')
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (classOrCategory == "category") {
        setSubject(data?.[0]?.category?.[0]?.course_Details ?? [])
      }
    }, 1500)
  }, [classOrCategory])

  // <==================== SEARCH ====================>
  const [searchData, setSearchData] = useState<any>([]);
  useEffect(() => {
    if (search != "") {
      let arr: any[] = [{
        classes: [],
        category: [],
        subject: [],
        demoRequest: []
      }]
      forSearchData?.map((item: any) => {
        if (item?.data?.class_name?.toLowerCase().includes(search?.toLowerCase())) {
          if (item?.type == "class")
            arr[0].classes.push(item)
        }
        if (item?.data?.name?.toLowerCase().includes(search?.toLowerCase())) {
          if (item?.type == "category") { arr[0].category.push(item) }
          if (item?.type == "category_subject") { arr[0].subject.push(item) }
        }
        if (item?.data?.title?.toLowerCase().includes(search?.toLowerCase()))
          arr[0].subject.push(item)
        if (item?.data?.subject_name?.toLowerCase().includes(search?.toLowerCase())) {
          if (item?.type == "demo_request")
            arr[0].demoRequest.push(item)
        }
      })
      if (arr.length > 0) {
        setSearchData(arr)
      }
    } else {
      setSearchData([])
    }
  }, [search])

  function classcategorysearch(type: string) {
    if (type === "classes") {
      setClassOrCategory("classes");
      getCategoryId("classes");
    }
    if (type === "category") {
      setClassOrCategory("category");
      getCategoryId("category");
    }
  }

  const [reciverId, setReciverId] = useState<any>(null);
  const [recivedMsgs, setRecivedMsgs] = useState<any>([]);

  // -------------------------------------------- UPCOMING CLASS API --------------------------------------------//
  function teacher_upcomming_past_data() {
    let formdata = new FormData();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/upcomming_past_data`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        const chatNames = response?.data?.data?.filter((obj: any, index: any) =>
          response.data.data.findIndex((event: any) => event.teacher_id == obj.teacher_id) == index
        );
        const filterItemArr: any = [];
        let allChatID: any = []
        chatNames.map((filterItem: any) => {
          allChatID?.push(localData?.role_id == 6 ? `${filterItem?.teacher_id}_${localData?.id}` : `${filterItem?.student_id}_${localData?.id}`)
          // setallReciversChatId(allChatID)
          setReciverId(localData?.role_id == 6 ? filterItem?.teacher_id : filterItem?.student_id)
          filterItemArr?.push(localData?.role_id == 6 ? filterItem?.teacher_id : filterItem?.student_id);
        })
      })
      .catch(function (error) {
        console.log(error, 'error');
      });
  }

  useEffect(() => {
    teacher_upcomming_past_data();
  }, [])

  // {UNREAD MESSAGE FUNCTIONALITY}
  let trueValues: any[] = [];
  if (trueValues?.length === 0) {
    recivedMsgs?.forEach((item: any) => {
      if (item?.seen == false) {
        trueValues?.push(item?.seen);
      }
    });

  }

  const reciversChatId = `${reciverId}_${localData?.id}`

  // -------------------------------------- FOR RECIVER -------------------------------------
  useEffect(() => {
    if (reciverId) {
      const q = query(
        collection(db, 'messages', 'solo-message', reciversChatId),
        orderBy('createdAt', 'desc'),
        limit(50)
      );
      const unsubscribe: Unsubscribe = onSnapshot(q, (QuerySnapshot) => {
        const fetchedMessages: any = [];
        QuerySnapshot.forEach((doc) => {
          fetchedMessages.push({ ...doc.data(), id: doc?.id });
        });
        const sortedMessages = fetchedMessages.sort(
          (a: any, b: any) => a.createdAt - b.createdAt
        );
        setRecivedMsgs(sortedMessages);
      });
      return unsubscribe;
    }

  }, [reciversChatId]);

  return (
    <>
      <ToastContainer />
      <Header count={notificatCount} forSearchData={forSearchData} searchFilter={searchFilter} classcategorysearch={classcategorysearch} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <NavLink to="referral-rewards" className={`${cx.gift}`}>
                <AiFillGift />
              </NavLink>
              <div className={`${cx.searchBox}`}>
                <BiSearch className={`${cx.icon}`} />
                <input type="text" className="form-control" placeholder="Search" value={search} onChange={(e: any) => {
                  setSearch(e.target.value);
                }} />
                {searchData[0]?.classes?.length > 0 || searchData[0]?.category?.length > 0 || searchData[0]?.subject?.length > 0 ?
                  <div className={`${cx.searchListView}`}>
                    <ul>
                      {
                        searchData[0]?.classes?.map((item: any, index: number) => {
                          return (
                            <li onClick={() => {
                              searchFilter(item?.data, "classes");
                              setSearchData([]);
                              setClassOrCategory("classes");
                              getCategoryId("classes");
                            }}>
                              {item?.data?.class_name}
                            </li>
                          )
                        })
                      }{
                        searchData[0]?.category?.map((item: any, index: number) => {
                          return (
                            <li onClick={() => {
                              searchFilter(item?.data, "category");
                              setSearchData([]);
                              setClassOrCategory("category");
                              getCategoryId("category");
                            }}>
                              {item?.data?.name}
                            </li>
                          )
                        })
                      }
                      {
                        searchData[0]?.subject?.map((item: any, index: number) => {
                          // console.log(item, "itme789")
                          return (
                            <li onClick={() => {
                              if (item?.type == "class_subject") {
                                navigate.push("/student/class-details", {
                                  ...item?.data,
                                  imageUrl: "https://appicsoftwares.in/guruCors/public/admin"
                                })
                              }
                              if (item?.type == "category_subject") {
                                navigate.push("/student/categorydetails", {
                                  ...item,
                                  imageUrl: "https://appicsoftwares.in/guruCors/public/admin/course/",
                                })
                              }
                              setSearchData([])
                            }}>
                              {item?.data?.name ? item?.data?.name : item?.data?.title} - {item?.name}
                            </li>
                          )
                        })
                      }
                      {
                        searchData[0]?.demoRequest?.map((item: any, index: number) => {
                          return (
                            <li onClick={() => {
                              navigate.push("/student/applied", {
                                state: item?.data,
                                imageUrl: "https://appicsoftwares.in/guruCors/public/admin",
                                courseUrl: "https://appicsoftwares.in/guruCors/public/admin/course/"
                              },)
                              setSearchData([])
                            }}>
                              {item?.data?.subject_name} - {item?.data?.class_name} (Demo)
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div> : ""
                }
              </div>
              <NavLink to="/student/notification" className={`${cx.notification}`}>
                <MdNotifications />
                <span>{notificatCount}</span>
              </NavLink>
              <NavLink to="/student/wallet" className={`${cx.wallet}`}>
                <FaWallet />
              </NavLink>
              <NavLink to="/student/chats" className={`${cx.notification}`}>
                <AiFillMessage />
                {trueValues?.length > 0 ? <span>{trueValues?.length}</span> : ''}
              </NavLink>
            </div>
          </div>
          <div className={`${st.spaceBox}`}></div>
          <div className={`${cx.contentBody} mt-3`}>
            <div className={`${cx.sliderHome}`}>
              <OwlCarousel className="owl-theme" {...options}>
                {data?.map((item: any) => {
                  return item?.banner?.map((e: any) => {
                    return (
                      <div className="item">
                        <div className={`${cx.slLink}`}>
                          <a href={e?.web_url} target="_blank"><img src={item.banners_url + e.image} /></a>
                        </div>
                      </div>
                    );
                  })
                })}
              </OwlCarousel>
            </div>

            {data?.length > 0 ? <div className={`${cx.menuList}`} >
              <NavLink to="#" className={`${classOrCategory === "classes" ? `${cx.homeTabdark}` : ""} ${cx.homeTabbtn}`}
                onClick={() => {
                  setloaderStatus(<ReactLoading type="spin" color="blue" width='40px' />)
                  setData([])
                  getCategoryId("classes");
                  setClassOrCategory("classes");
                  window.scrollTo(0, 0)
                }}>
                Class
              </NavLink>
              <NavLink to="#" className={`${classOrCategory === "category" ? `${cx.homeTabdark}` : ""} ${cx.homeTabbtn}`}
                onClick={() => {
                  setloaderStatus(<ReactLoading type="spin" color="blue" width='40px' />)
                  // setData([])
                  getCategoryId("category");
                  setClassOrCategory("category");
                  window.scrollTo(0, 0)
                }}>
                Category
              </NavLink>
            </div> : ""}

            <div className={`${cx.catHome}`}>
              {/* CLASSES CAROUSEL */}
              <OwlCarousel className="owl-theme" {...options2}>
                {classOrCategory?.length == 0 && (
                  <div className="d-flex justify-content-center">
                  </div>
                )}
                {classOrCategory === "classes" && data?.map((item: any) => {
                  return item?.classes?.map((e: any, i: number) => {
                    return (
                      <div className="item" key={e?.id}>
                        <button className={activeClass == e?.id ? `${cx.catLink} ${cx.active}` : cx.catLink} defaultChecked={e?.id}
                          onClick={() => {
                            setClassOrCategory("classes");
                            setSubject(e?.subject_Details);
                            setActiveClass(e?.id);
                          }}>
                          <img src={item.class_images + e.image} />
                          <h6>{e?.class_name}</h6>
                        </button>
                      </div>
                    );
                  })
                })}
                {classOrCategory === "category" && data?.map((item: any) => {
                  return item?.category?.map((e: any) => {
                    console.log(e, "emememememkkk")
                    return (
                      <div className="item" key={e?.id}>
                        <button className={activeClass == e?.id ? `${cx.catLink} ${cx.active}` : cx.catLink} defaultChecked={e?.id}
                          onClick={() => {
                            setClassOrCategory("category");
                            setSubject(e?.course_Details);
                            setActiveClass(e?.id)
                          }}>
                          <img src={item.category_images + e.image} />
                          <h6>{e.name}</h6>
                        </button>
                      </div>
                    );
                  })
                })}
              </OwlCarousel>
            </div>

            <Row>
              {subject?.length == 0 && (
                <div className="d-flex justify-content-center">
                  {loaderStatus}
                </div>
              )}

              {/* CLASSES AND CATEGORY DATA */}
              {subject?.map((item: any, index: number) => {
                console.log(item, "itemmmmmm")
                return (
                  <Col lg={4} key={item.id}>
                    <span className={`${cx.classList}`}
                      onClick={() => {
                        if (classOrCategory === "classes") {
                          navigate.push("/student/class-details", {
                            ...item,
                            imageUrl: "https://appicsoftwares.in/guruCors/public/admin",
                            // plans: data[0]?.classes
                            plans: item?.plans
                          },)
                        } else {
                          navigate.push("/student/categorydetails", {
                            ...item,
                            imageUrl: "https://appicsoftwares.in/guruCors/public/admin/course/",
                          },)
                        }
                      }}>
                      <div className={`${cx.classImg}`}>
                        {/* SUBJECT & COURSES IMAGE */}
                        <img src={item?.title ? item?.image == "" ? defaultImg :
                          `${image}/${item?.image}` : `${course_images}/${item?.image}`} />
                      </div>
                      <div className={`${cx.classListBody}`}>
                        <h5>{item?.title ?? item?.name}</h5>
                        <p>{removeTags(item?.description ?? item?.overview)}</p>
                        <p className="text-end"><FaArrowAltCircleRight className={`${cx.arrow}`} /></p>
                      </div>
                    </span>
                  </Col>
                );
              })}

              {/* student/categorydetails */}
              {data?.[0]?.classes?.[0]?.subject_Details?.length > 0 ? <Col lg={12} className="text-end mt-3">
                <span className={`${st.cursor} ${cx.seeAllBtn}`}
                  onClick={() => {
                    if (classOrCategory === "classes") {
                      navigate.push("/student/course", {
                        type: "classes"
                      })
                    } else {
                      navigate.push("/student/course", {
                        type: "category"
                      })
                    }
                  }
                  }> See All
                </span>
              </Col> : ""}
            </Row>

            {/* -----------------------------DEMO REQUEST------------------------------ */}
            {data?.[0]?.demo_request?.length > 0 ? <div>
              <h5>Demo Request</h5>
            </div> : ""}
            <Row>
              {data?.length == 0 && (
                <div className="d-flex justify-content-center">
                  {loaderStatus}
                </div>
              )}
              {data?.map((item: any, index: any) => {
                // console.log(item, "wvurbvurwvwrvrwv")
                return item?.demo_request?.map((e: any) => {
                  // console.log(item.subject_images + e.subject_image, e, "wvouwbvuwbviwvw")
                  return (
                    <Col lg={4}>
                      <span
                        onClick={() => {
                          if (classOrCategory === "classes") {
                            navigate.push("/student/applied", {
                              state: item?.demo_request,
                              showData: e,
                              imageUrl: item?.class_images,
                              courseUrl: item?.course_images,
                              categoryUrl: item?.category_images,
                            },)
                          }
                          else {
                            navigate.push("/student/applied", {
                              state: item?.demo_request,
                              showData: e,
                              imageUrl: item?.class_images,
                              courseUrl: item?.course_images,
                              categoryUrl: item?.category_images
                            },)
                          }
                        }}
                        className={`${cx.classList}`}>
                        <div className={`${cx.classImg}`}>
                          <img src={e?.category_id == 0 ? item?.subject_images + e.subject_image : item?.course_images + "/" + e.courses_image} />
                          <span className={e.assigned_stataus == 1 ? `${cx.applied}` : e.assigned_stataus == 2 ? `${cx.assign}` : `${cx.pending}`}>{e.assigned_stataus == 1 ? "Demo Request Applied" : e.assigned_stataus == 2 ? "Tutor Assigned" : "Pending"}</span>
                          {/* <span className={e.apply_status == e.assigned_stataus ? `${cx.assign}` : `${cx.applied}`}>{e.apply_status == e.assigned_stataus ? "Applied" : "Assign"}</span> */}
                        </div>
                        <div className={`${cx.classListBody}`}>
                          <h5>{e?.subject_name ? e?.subject_name : e?.courses_name}</h5>
                          <p>{removeTags(e?.subject_description || e?.courses_description)}</p>
                        </div>
                      </span>
                    </Col>
                  );
                })
              })}
            </Row>

          </div>
          <div className={`pt-3 ${st.spaceBox}`}></div>
          <Bottombar />
        </div>
      </section>
      <Footer />
    </>
  );
}