import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Reschedule.module.scss";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import Rightbody from "../../../components/Student/Rightbody/Rightbody";
import CustomDatePicker from '../../../components/Student/Datepicker/CustomDatePicker';
import Header from "../../../components/Header/Header";
import { addDays, subDays, format } from "date-fns";
import Footer from "../../../components/Teacher/Footer/Footer";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { localeData } from "moment";

export default function Reschedule() {
  let localData = JSON.parse(localStorage.getItem("userData")!);
  const navigate = useHistory();

  const selectedDay = (val: any) => {
    let date = format(val, "yyyy-MM-dd")
    // studentCalendar(date)
    studentSlotBook(date)
    setSelectDay(val.getDay())
  };
  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );
  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const location: any = useLocation();

  const [data, setData] = useState([]);
  const [studentapplyfordemo_id, setStudentapplyfordemo_id] = useState("")
  const [selectDay, setSelectDay] = useState(0);
  const [get_TeacherAvailabiltyOfSlots, setGet_TeacherAvailabiltyOfSlots] = useState([]);
  const [teacher_availabilty_of_slots_details, setTeacher_availabilty_of_slots_details] = useState([]);

  // --------------------------------STUDENT BOOK SLOT-----------------------------------//
  function studentSlotBook(date: any) {
    let formdata = new FormData();
    formdata.append('date', date);
    formdata.append('teacher_id', location?.state?.teachersId || location?.state?.teacher_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/book_Slot_student`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        setData(response.data.data);
        setGet_TeacherAvailabiltyOfSlots(response.data.get_TeacherAvailabiltyOfSlots);
        setTeacher_availabilty_of_slots_details(response.data.get_TeacherAvailabiltyOfSlots.teacher_availabilty_of_slots_details);
        setStudentapplyfordemo_id(response.data.data.studentapplyfordemo_id)
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
      })
      .catch(function (error) {
        notify("Something went wrong");
      });
  }

  // --------------------------------RESCEDULE DEMO CLASS API-----------------------------------//
  function rescheduleDemocls() {
    let formdata = new FormData();
    formdata.append('id', location?.state?.item?.studentapplyfordemo_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/reschedule_demo_by_teacher`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          localStorage.setItem("userData", JSON.stringify(response.data.data))
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/teacher/home',
            });
          }, 2000);
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
        setData(response.data.data);
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }

      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/teacher/home" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Reschedule</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${st.spaceBox}`}></div>

          <div className={`${cx.contentBody} mt-3`}>
            <ul className={`${cx.rescheduleContent}`}>
              <li>
                <h6>{location?.state?.item?.student_name}</h6>
                <p className={`${cx.classPrice}`}>Class Rate - {localData?.currency.slice(4)}{location?.state?.item?.class_rate}</p>
              </li>
              <li>
                <p>Subject - {location?.state?.item?.subject_name}</p>
                <p>Class - {location?.state?.item?.class_name}</p>
              </li>
            </ul>
            <CustomDatePicker getSelectedDay={selectedDay} labelFormat={"MMMM"} color={"#374e8c"} />

            <div className={`${cx.timeSlot}`}>
              <h5>Morning</h5>
              <ul>
                <li><button>7:00 - 8:00</button></li>
                <li><button>8:00 - 9:00</button></li>
                <li><button>9:00 - 10:00</button></li>
              </ul>

              <Col className="col-12">
                <NavLink to="#" className={`btn ${cx.continue}`}
                  onClick={rescheduleDemocls}>
                  Next
                </NavLink>
              </Col>
            </div>
          </div>

          <div className={`pt-3`}></div>
        </div>
        {/* <Rightbody /> */}
      </section>
      <Footer />
    </>
  );
}