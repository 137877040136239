import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Privacypolicy.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import Footer from "../../../components/Teacher/Footer/Footer";
import Header from "../../../components/Header/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Teacherprivacypolicy() {
  const [data, setData] = useState<any>({});
  useEffect(() => {
    privacyPolicy();
  }, [])

  function removeTags(str: any) {
    if ((str === null) || (str === '') || (str === undefined))
      return false;
    else
      str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // -------------------------------------------- PRIVACY POLICY API ------------------------------------------------//
  function privacyPolicy() {

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/aboutUsPrivacy_PolicyTerms_Condition`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
    };
    axios(config)
      .then(function (response) {
        setData(response.data.data)
      })
      .catch(function (error) {
        notify("Something went wrong");
      });
  }

  return (
    <>
      <Header />
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                  <NavLink to="/teacher/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.aboutUs}`}>
                  <h6>Privacy Policy</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
          </div>
          <div className={`container ${cx.contentBody} mt-3`}>
            <Form>
              <Form.Group className={`${st.formField}`} controlId="">
                <h5 className="mb-3">Privacy Policy</h5>
                <p className={`${st.fontColor} text-left mt-2 mb-3`} style={{ fontSize: '15px' }}>{removeTags(data?.privacy_policy)}</p>
              </Form.Group>
            </Form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}