import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Payout.module.scss";
import { Col, Row, Button } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { HiOutlineDownload } from "react-icons/hi";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../../components/Teacher/Footer/Footer";
import Header from "../../../components/Header/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Teacherpayout() {

  let localData = JSON.parse(localStorage.getItem("userData")!);
  let date: any = new Date().toISOString();
  let maxDate: any = date.split("T");
  let minusDateFrom: any = +maxDate[0].slice(8) - 3
  let minusDateTo: any = +maxDate[0].slice(8) - 3
  let finalFromDate = maxDate[0].slice(0, 8) + `${minusDateFrom < `10` ? `0` + minusDateFrom : minusDateFrom}`
  let finalToDate = maxDate[0].slice(0, 8) + `${minusDateTo < `10` ? `0` + minusDateTo : minusDateTo}`

  const navigate = useHistory();

  // Get current date
  var date1: any = new Date();
  date1.setDate(date1.getDate() + 3);
  var d: any = new Date()
  d?.setDate(d?.getDate() - 3);

  const [from_date, setFrom_date] = useState('');
  const [to_date, setTo_date] = useState('');
  const [data, setData] = useState<any>([]);
  const [total_amount, setTotal_amount] = useState(0);
  const [withdrowAmount, setWithdrowAmount] = useState('');
  const [withdrowBtn, setWithdrowBtn] = useState<any>(false);
  const [ids, setIds] = useState("");

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    getWithdrow()
  }, [])

  useEffect(() => {
    findAmount()
  }, [to_date])

  // ---------------------------WITHDROW API-------------------------- //
  function withdrow() {

    let formdata = new FormData();
    formdata.append('from_date', from_date);
    formdata.append('to_date', to_date);
    formdata.append('amount', withdrowAmount);
    formdata.append('enroll_schedule_id', ids);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/withdrowamount`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setWithdrowAmount(response.data.total_amount);
          setTotal_amount(response.data.total_amount);
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/teacher/profile',
              state: { detail: response.data.id }
            });
          }, 3000);
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        console.log(error, 'errororo')
        notify("Something went wrong, Please try again later");
      });
  }

  // ---------------------------FIND AMOUNT API-------------------------- //
  function findAmount() {

    let formdata = new FormData();
    formdata.append('from_date', from_date);
    formdata.append('to_date', to_date);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher_withdrowamount`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        console.log(response, "wegiowhg0984209092094")
        if (response.data.statusCode == 200) {
          setWithdrowAmount(response.data.total_amount);
          setIds(response?.data?.ids)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  // ---------------------------GET WITHDROW DETAILS API-------------------------- //
  function getWithdrow() {
    let formdata = new FormData();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/get_withdrowamount`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        setTotal_amount(response.data.total_amount);
        setData(response.data.data);
        if (response.data.statusCode == 200) {
        }
      })
      .catch(function (error) {
        notify("Something went wrong, Please try again later");
      });
  }

  //---------------------------- AM PM FUNCTION FOR TIME ----------------------------//
  function tConvert(time: any) {
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time?.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  return (
    <>
      <Header />
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/teacher/my-earning" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Payout</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>
          <div className={`${cx.contentBody}`}>
            <Row>
              <Col lg={6} className={`m-auto`}>
                <div className={`${cx.payoutCard}`}>
                  <h4>Total Amount</h4>
                  <input type="text" value={localData?.currency.slice(4) + total_amount === undefined ? 0 : total_amount} disabled />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className={`m-auto`}>
                <div className={`${cx.classCard}`}>
                  <div className={`${cx.classForm}`}>
                    <div className={`${cx.classDate}`}>
                      <label>From</label>
                      <input type="date"
                        // max={finalFromDate}
                        onChange={(e) => {
                          setFrom_date(e.target.value)
                        }} />
                    </div>
                    <div className={`${cx.classDate}`}>
                      <label>To</label>
                      <input type="date"
                        // max={finalToDate}
                        onChange={(e) => {
                          setTo_date(e.target.value);
                        }} />
                    </div>
                  </div>
                  <div className={`${cx.withdrowBox}`}>
                    <input disabled placeholder="Enter the amount" value={withdrowAmount ? withdrowAmount : localData?.currency.slice(4)} />
                    <Button disabled={withdrowBtn !== false}
                      className={`${cx.withdrowBtn}`}
                      onClick={() => {
                        setWithdrowBtn(true);
                        withdrow();
                        setTimeout(() => {
                          setWithdrowBtn(false);
                        }, 4600);
                      }}>
                      Withdraw
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              {data?.map((item: any) => {
                return (
                  <Col lg={4}>
                    <div className={`${cx.classCard}`}>
                      <ul>
                        <li>
                          <span><h5>Withdrawal Amount </h5></span>
                          <span className={`${cx.themeColor}`}>{localData?.currency.slice(4)}{item?.withdrowral_amount}</span>
                        </li>
                        <li>
                          <span>Date - {item?.request_date}</span>
                          <span className={`${cx.greenColor}`}>
                            {
                              (item?.withdrowral_status == 1 ? "Pending" : "Approved")
                            }
                          </span>
                        </li>
                        <li>
                          <span>Time -  {tConvert(item?.request_time.slice(0, 5))}</span>
                          <a href={item?.url + "/" + item?.image} className={`${cx.themeColor}`}> {item?.image == null ? <div aria-disabled></div> : <HiOutlineDownload />} {item?.image == null ? <div aria-disabled></div> : "Receipt"}</a>
                        </li>
                        <li>
                          <span>Transaction ID - <p> {item?.transation_id.slice(0, 20)}...</p></span>
                        </li>
                      </ul>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}