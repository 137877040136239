import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Passwordforgot.module.scss";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FaKey } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";
import Rightbody from "../../../components/Student/Rightbody/Rightbody";

export default function Passwordforgot() {

  const location: any = useLocation()
  let id = location?.state?.detail
  let email = location?.state?.email
  let localData = JSON.parse(localStorage.getItem("userData")!);

  const navigate = useHistory();
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);

  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [userid, setUserid] = useState(localData?.id);
  const [newPassword, setnewPassword] = useState(localData?.newPassword);
  const [confirmPassword, setConfirmPassword] = useState(localData?.confirmPassword);

  let formdata = new FormData();

  function forgotPass(postdata: any) {

    let formdata = new FormData();
    formdata.append('userId', id);
    formdata.append('email', postdata.email);
    formdata.append('newPassword', postdata.newPassword);
    formdata.append('confirmPassword', postdata.confirmPassword);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/reset-password`,
      headers: {
        "Content-Type": "multipart/form-data;",
        // Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/student/login',
              state: { detail: response.data.id, email: postdata.email, component: "signup" }
            });
          }, 2000);
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }

      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col>
                  <NavLink to="/student/forgot-password" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col>
                </Col>
                <Col className="text-end">
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img src={logo} className={`${cx.logo}`} />
            </Col>
          </div>

          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={'m-auto'}>
              <h5 className={`${st.themeColor}`}>Change Password</h5>
              <p className={`${st.fontColor}`}>Please enter your new password</p>
            </Col>
            <Col lg={4} className={'m-auto'}>
              <Form onSubmit={handleSubmit(forgotPass)} className={`${st.formFull}`}>
                <Form.Group className={`${st.formField}`} controlId="">
                  <div className="position-relative">
                    <Form.Control type={show ? "password" : "text"} placeholder="Enter New Password"

                      {...register("newPassword", {
                        required: "This password is required",
                        minLength: {
                          value: 6,
                          message: "Please enter minimum 6 characters",
                        },
                      })}
                    />
                    {show ? (
                      <AiFillEyeInvisible
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow(false);
                        }}
                      />
                    ) : (
                      <AiFillEye
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow(true);
                        }}
                      />
                    )}
                  </div>

                  {errors?.newPassword?.type === "minLength" && (
                    <p style={{ color: "red" }}>
                      Please enter minimum 6 characters
                    </p>
                  )}
                  {errors?.newPassword?.type === "required" && (
                    <p style={{ color: "red" }}>This field is required</p>
                  )}
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <div className="position-relative">
                    <Form.Control type={show2 ? "password" : "text"} placeholder="Enter Confirm Password"

                      {...register("confirmPassword", {
                        required: "This password is required",
                        minLength: {
                          value: 6,
                          message: "Please enter minimum 6 characters",
                        },
                      })}
                    />
                    {show2 ? (
                      <AiFillEyeInvisible
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow2(false);
                        }}
                      />
                    ) : (
                      <AiFillEye
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow2(true);
                        }}
                      />
                    )}
                  </div>

                  {errors?.confirmPassword?.type === "minLength" && (
                    <p style={{ color: "red" }}>
                      Please enter minimum 6 characters
                    </p>
                  )}
                  {errors?.confirmPassword?.type === "required" && (
                    <p style={{ color: "red" }}>This field is required</p>
                  )}
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    {/* <NavLink to="#" className={`btn ${st.actionBtn}`}>
                      Submit
                    </NavLink> */}
                    <button type="submit" className={`btn ${st.actionBtn}`}>
                      Submit
                    </button>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </div>
        </div>
        {/* <Rightbody /> */}
      </section>
    </>
  );
}