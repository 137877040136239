import React from "react";
import cx from "./Footer.module.scss";

import logo from "../../../images/logo-white.png";

import { Col, Row } from "react-bootstrap"
import { NavLink } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";

const Footer = (props: any) => {
    return (<>
        <div className={`${cx.footerSection}`}>
            <div className={`${cx.footerStart}`}>
                <div className={'container'}>
                    <Row className={`${cx.titleBar}`}>
                        <Col className={`col-12 text-center`}>
                            <div className={`${cx.footerLeft}`}>
                                <NavLink to="#" className={`${cx.logo}`}>
                                    <img alt="logo" src={logo} />
                                </NavLink>
                                <ul>
                                    <li><NavLink to="/student/about-us">About Us</NavLink></li>
                                    <span>|</span>
                                    <li><NavLink to="/student/help-and-support">Help & Support</NavLink></li>
                                    <span>|</span>
                                    <li><NavLink to="/student/privacy-policy">Privacy Policy</NavLink></li>
                                    <span>|</span>
                                    <li><NavLink to="/student/Terms-and-conditions">Terms & Conditions</NavLink></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>

        <div className={`${cx.logoFixed}`}>
            <a target="_blank" href="https://api.whatsapp.com/send?phone=918440868293"><IoLogoWhatsapp /></a>
        </div>
    </>)
}

export default Footer;