import React from "react";
import m from "./Modal.module.scss";
import { Modal, Row, Col } from "react-bootstrap";
import ProfileAvatar from "../../../Custom_Components/ProfileAvatar/ProfileAvatar";

const TeacherDemoDetails = (props: any) => {
    let { showDemo, handleDemoStudentDetailsClose } = props;
    return (
        <>
            <Modal
                centered
                show={showDemo}
                onHide={handleDemoStudentDetailsClose}
                aria-labelledby="example-modal-sizes-title-lg"
                size="lg"
                className={`${m.modalCts}`}>
                <Modal.Body>
                    <div className={`${m.student_detailsPopup}`}>
                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <div className={`${m.imgbox}`}>
                                        {props?.demoDetails?.profile_img == null || props?.demoDetails?.profile_img == "" ?
                                            <ProfileAvatar name={props?.demoDetails?.student_name} />
                                            : <img className={`${m.imgClass}`} src={props?.demoDetails?.profile_img} alt={props?.demoDetails?.student_name} />
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Name:</label>
                                    <div className="form-control">{props?.demoDetails?.student_name}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Curriculum:</label>
                                    <div className="form-control">{props?.demoDetails?.curriculum || "NA"}</div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3">
                                    <label>Query:</label>
                                    <div className="form-control">{props?.demoDetails?.query_no || 'NA'}</div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3">
                                    <label>Current Grade:</label>
                                    <div className="form-control">{props?.demoDetails?.class_name}</div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3">
                                    <label>Time Zone:</label>
                                    <div className="form-control">{props?.demoDetails?.timezone}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Country:</label>
                                    <div className="form-control">{props?.demoDetails?.country}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Preferred Timing:</label>
                                    <div className="form-control">{props?.demoDetails?.student_preference?.[0]?.preferred_time || "NA"}</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={`${m.btnGroup}`}>
                        <button style={{ backgroundColor: "#375dbc", color: "#fff" }} className={`btn ${m.actionBtn}`} onClick={handleDemoStudentDetailsClose}>Close</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TeacherDemoDetails;