import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { GlobalContextProvider } from './store/global-context';
import {
  BrowserRouter as Router
} from "react-router-dom";
import { store } from "./redux-toolkit/store/store";
import { Provider } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <Router basename="/">
    <GlobalContextProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </GlobalContextProvider>
  </Router>, document.getElementById("root"));