import React from "react";
import st from "../../style.module.scss";
import cx from "./Splash.module.scss";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../images/logo.png";

export default function Splash() {
  return (
    <>
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${cx.splesHight}`} >
          <div className={`${cx.contentBody} mt-3`}>
            <div className={`${cx.splashBox}`}>
              <img className={`${cx.logo}`} src={logo} />
              <div className={`${cx.splashBoxBtns}`}>
                <NavLink className={`btn ${cx.studentBtn}`} to="/student/login">Continue as Student</NavLink>
                <NavLink className={`btn ${cx.teacherBtn}`} to="/teacher/login">Continue as Teacher</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
