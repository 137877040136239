import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Calendar.module.scss";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { format } from "date-fns";
import CustomDatePicker from '../../../components/Student/Datepicker/CustomDatePicker';
import Bottombar from "../../../components/Teacher/Bottombar/Bottombar";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/Teacher/Footer/Footer";
import Header from "../../../components/Header/Header";
import ReactLoading from "react-loading";

export default function Calendar() {
  const [date, setDate] = useState('');
  const selectedDay = (val: any) => {
    let date: any = format(val, "yyyy-MM-dd")
    setDate(date);
    teacherCalendar(date)
    setloaderStatus(<ReactLoading type="cylon" color="blue" />)
  };

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  //---react-toastify---//
  const [timenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  const [data, setData] = useState<any>([]);
  const [getEnroll, setGetEnroll] = useState<any[]>([]);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  let localData = JSON.parse(localStorage.getItem("userData")!);

  function teacherCalendar(date: any) {

    let formdata = new FormData();
    formdata.append('class_end_date', date);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher_calendar`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        setData(response.data.data)
        setGetEnroll(response.data.getEnroll)
        if (response.data.data.length === 0) {
          setloaderStatus(<div>Class Not Found...</div>);
        }
      })
      .catch(function (error) {
        notify("Something went wrong");
      });
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // AM PM FUNCTION
  function tConvert(time: any) {
    // Check correct time format and split into components
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time?.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Calendar</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>
          <div className="container">
            <CustomDatePicker getSelectedDay={selectedDay} labelFormat={"MMMM"} color={"#374e8c"} />
          </div>
          <div className={`container ${cx.contentBody}`}>

            {/* CLASSES DATA */}
            <Row>
              {
                data?.length == 0 && getEnroll?.length == 0 ? (
                  <div className="d-flex justify-content-center">
                    {loaderStatus}
                  </div>
                ) :
                  data?.map((item: any, index: any) => {
                    // let localTime = new Date(`${item?.select_date} ${item?.select_time}`)?.toLocaleString("en-US", { timeZone: localData?.timezone });
                    let localTime = new Date(`${item?.utc_select_date}`)?.toLocaleString("en-US", { timeZone: localData?.timezone });
                    let showTime = localTime.split(",")
                    let matchDate1 = localTime?.slice(0, 10)?.split("/");
                    let date1 = +matchDate1[0] < 10 ? `0${matchDate1[0]}` : matchDate1[0]
                    let date2 = +matchDate1[1] < 10 ? `0${matchDate1[1]}` : matchDate1[1]
                    let matchDate2 = date?.split('-')
                    if (Number(`${date1}${date2}${matchDate1[2]?.slice(0, 4)}`) == Number(`${matchDate2[1]}${matchDate2[2]}${matchDate2[0]}`)) {
                      return (
                        <Col lg={4}>
                          <div className={`${cx.classCard}`}>
                            <ul>
                              <button className="btn" style={{ width: '94px', marginBottom: "9px" }}>Demo</button>
                              <li>
                                <span><h5>{item?.student_name}</h5></span>
                                <span>Time -{tConvert(showTime[1]) || "Not Available"}</span>
                              </li>
                              <li>
                                <span>{item?.subject_name ? "Subject" : "Course"} - {item?.subject_name || item?.category_name}</span>
                                <span>{item?.class_name ? "Class" : "Category"} - {item?.class_name || item?.courses_name}</span>
                              </li>
                              <li>
                                <span>Class Url - </span>
                                <span><a href={`//${item?.class_url}`}>{item?.class_url.slice(0, 20)}</a></span>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      );
                    };
                  })}
            </Row>

            {/* Data enroll */}
            <Row>
              {
                getEnroll?.map((item: any, index: any) => {
                  let localTime = new Date(`${item?.select_date} ${item?.start_time} UTC`)?.toLocaleString("en-US", { timeZone: localData?.timezone });
                  let showTime = localTime.split(",")
                  let matchDate1 = localTime?.slice(0, 10)?.split("/");
                  let date1 = +matchDate1[0] < 10 ? `0${matchDate1[0]}` : matchDate1[0]
                  let date2 = +matchDate1[1] < 10 ? `0${matchDate1[1]}` : matchDate1[1]
                  let matchDate2 = date?.split('-')
                  if (Number(`${date1}${date2}${matchDate1[2]?.slice(0, 4)}`) == Number(`${matchDate2[1]}${matchDate2[2]}${matchDate2[0]}`)) {
                    return (
                      <Col lg={4}>
                        <div className={`${cx.classCard}`}>
                          <ul>
                            <li>
                              <span><h5>{item?.student_name}</h5></span>
                              <span>Time -{showTime[1] || "Not Available"}</span>
                            </li>
                            <li>
                              <span>{item?.subject_name ? "Subject" : "Course"} - {item?.subject_name || item?.category_name}</span>
                              <span>{item?.class_name ? "Class" : "Category"} - {item?.class_name || item?.courses_name}</span>
                            </li>
                            <li>
                              <span>Class Url - </span>
                              <span><a href={`//${item?.class_url}`}>{item?.class_url.slice(0, 20)}</a></span>
                            </li>
                          </ul>
                          {/* <Row>
                          <Col>
                            <NavLink className='btn' to="/student/reschedule">Reschedule</NavLink>
                          </Col>
                        </Row> */}
                        </div>
                      </Col>
                    );
                  }
                })}
            </Row>
          </div>
          <div className={`${st.spaceBox}`}></div>
          <Bottombar />
        </div>
      </section>
      <Footer />
    </>
  );
}