import React from "react";
import st from "../../style.module.scss";
import cx from "./Error404.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
export default function Error404() {

  return (
    <>
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Error!</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${st.spaceBox}`}></div>
          <div className={`${cx.contentBody} mt-3`}>
            <div className={`${cx.detailsBox}`}>
              <h5>404</h5>
              <p>Page Note Found !</p>
              <NavLink to="/" className={`btn mt-3 ${cx.bookBtn}`}>
                Got to Home
              </NavLink>
            </div>
          </div>
          <div className={`pt-3`}></div>
        </div>
      </section>
    </>
  );
}
