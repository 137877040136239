import React from 'react';
import Avatar from 'react-avatar';

const ProfileAvatar = ({ name }: any) => {
    return (
        <Avatar
            name={name}
            color='#375dbc'
            size="50"
            round={true}
            style={{ paddingRight: "0px !important" }} // Customize the style as needed
        />
    );
};

export default ProfileAvatar;