import React, { useEffect, useState } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Paymenthistory.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img.svg";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import ReactLoading from "react-loading";

export default function Paymenthistory() {

  const [data, setData] = useState([]);
  const [url, setUrl] = useState('')
  let localData = JSON.parse(localStorage.getItem("userData")!);

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  // --------------------------PAYMENT HISTORY API-------------------------- //
  function paymentHistory() {

    let formdata = new FormData();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/payment_history`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>No Data Found ...</div>);
        }
        setUrl(response.data.url)
        setData(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    paymentHistory();
  }, [])

  // CONVERTING 24 HOURS FORMAT TO 12 HOURS FORMAT
  function tConvert(time: any) {
    // Check correct time format and split into components
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time?.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.aboutUs}`}>
                  <h6>Payment History</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
          </div>

          <div className={`container ${cx.contentBody} mt-3`}>
            <Row>
              {data?.length == 0 && (
                <div className="d-flex justify-content-center">
                  {loaderStatus}
                </div>
              )}
              {data?.map((item: any) => {
                let localTime = new Date(`${item?.created_at}`).toLocaleString("en-US", { timeZone: localData?.timezone });
                return (
                  <Col lg={4}>
                    {item?.invoice_pdf != null ? <div className={`${cx.notificatBox} ${cx.notificatOne}`}>
                      <div className={`${cx.notificationText}`}>
                        <div className={`${cx.notificationTitle}`}>
                          <h6>{item?.payment_status}</h6>
                          <p>{item?.currency} {item?.amount_to_pay}</p>
                        </div>
                        <p><span>{localTime}</span></p>
                        <p>Transaction ID - <span> {item?.transation_id}</span></p>
                        <a target="_blank" href={url + "/" + item?.invoice_pdf}>Download Invoice</a>
                      </div>
                    </div> : ''}
                  </Col>
                )
              })}
            </Row>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}