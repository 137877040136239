import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    classCount: 0,
}

const classCount: any = createSlice({
    name: "classCount",
    initialState,
    reducers: {
        changeClassCount(state = initialState, action) {
            // console.log(action, "11111acttiorrnnn")
            state.classCount = action?.payload
            // state.countState = action?.payload?.length
        },

    },
});
export const { changeClassCount } = classCount.actions;
export default classCount.reducer;