import React from "react";
import cx from "./Bottombar.module.scss";
import home from "../../../images/home.svg";
import classes from "../../../images/classes.svg";
import profile from "../../../images/profile.svg";
import calendar from "../../../images/calendar.svg";
import { Col, Row } from "react-bootstrap"
import { NavLink } from "react-router-dom";

const Bottombar = (props: any) => {
    return (
        <>
            <div className={`${cx.bottomMenu}`}>
                <Row>
                    <Col>
                        <NavLink className={`${cx.menu}`} to="/teacher/home">
                            <img src={home} alt="home" className={`${cx.icon}`} />
                            <h5>Home</h5>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink className={`${cx.menu}`} to="/teacher/classes">
                            <img src={classes} alt="classes" className={`${cx.icon}`} />
                            <h5>Classes</h5>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink className={`${cx.menu}`} to="/teacher/calendar">
                            <img src={calendar} alt="calendar" className={`${cx.icon}`} />
                            <h5>Calendar</h5>
                        </NavLink>
                    </Col>
                    <Col>
                        <NavLink className={`${cx.menu}`} to="/teacher/profile">
                            <img src={profile} alt="profile" className={`${cx.icon}`} />
                            <h5>Profile</h5>
                        </NavLink>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Bottombar;