import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Notification.module.scss";
import { NavLink, useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { MdArrowBackIos } from "react-icons/md";
import bg from "../../../images/bg1.svg";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from "react-loading";

export default function Notification() {
  const [data, setData] = useState<any[]>([]);
  const [id, setid] = useState<any>("");

  const [loaderStatus, setloaderStatus] = useState<any>(
    <ReactLoading type="cylon" color="blue" />
  );

  const navigate = useHistory()

  useEffect(() => {
    notification()
  }, [])

  useEffect(() => {
    if (id !== "") {
      notificationCount()
    }
  }, [id])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // ----------------------- NOTIFCATION API ----------------------- //
  function notification() {
    let formdata = new FormData();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/get_notification`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        // console.log(response, "response")
        setData(response?.data?.data);
        if (response?.data?.data) {
          setloaderStatus(<div>Notification Not Found ...</div>);
        }
        let idVal: any = response?.data?.data?.map((item: any) => {
          // console.log(item, "oubvwvbwvoibwvow")
          return item?.id
        }).join(",")
        setid(idVal)
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  // ----------------------- NOTIFCATION COUNT API ----------------------- //
  function notificationCount() {
    let formdata = new FormData();
    formdata.append("id", id);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/read_notification`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Notification Not Found ...</div>);
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/home" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.aboutUs}`}>
                  <h6>Notification</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
          </div>

          <div className={`container ${cx.contentBody} mt-3`}>
            <Row>
              {
                data?.length == 0 ? (
                  <div className="d-flex justify-content-center">
                    {loaderStatus}
                  </div>
                ) :
                  data?.map((item: any) => {
                    let newDate: any = new Date(item?.created_at).toLocaleString();
                    return (
                      <Col lg={12}>
                        <div className={`${cx.notificatBox} ${cx.notificatOne}`}
                          onClick={() => {
                            if (item?.title == "Assignment" || item?.title == "Enroll Class End" || item?.title == "Class Ended") {
                              // navigate.push({
                              //   pathname: '/student/classes',
                              //   state: item?.title,
                              // });
                              navigate.push('/student/classes', {
                                state: item?.title,
                                index: 2
                              })
                            } else if (item?.title == "Refer Reward") {
                              navigate.push({
                                pathname: '/student/referral-rewards',
                              });
                            } else if (item?.title == "Credites" || item?.title == "Enroll Reschedule Class" ||
                              item?.title == "Enroll Class Start Now" || item?.title == "Enroll Class End" ||
                              item?.title == "Purchase Creadit" || item?.title == "Join Now") {
                              navigate.push('/student/classes', {
                                state: item?.title,
                                index: 0
                              });
                            } else {
                              navigate.push('/student/classes', {
                                state: 'Class Start Now',
                                index: 1
                              })
                            }
                          }}>
                          <div className={`${cx.notificatImg}`}>
                          </div>
                          <div className={`${cx.notificationText}`}>
                            <div className={`${cx.notificationTitle}`}>
                              <h6>{item?.user_name}</h6>
                              <p>{newDate}</p>
                            </div>
                            <p>{item?.message}</p>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
            </Row>
          </div>
        </div>
      </section >
      <Footer />
    </>
  );
}