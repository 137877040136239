import React from "react";
import m from "./Modal.module.scss";
import { Modal, Row, Col } from "react-bootstrap";
import ProfileAvatar from "../../../Custom_Components/ProfileAvatar/ProfileAvatar";

const TeacherDetails = (props: any) => {
    let { show, handleTeacherDetailsClose } = props;

    return (
        <>
            <Modal
                centered
                show={show}
                onHide={handleTeacherDetailsClose}
                aria-labelledby="example-modal-sizes-title-lg"
                size="lg"
                className={`${m.modalCts}`}>
                <Modal.Body>
                    <div className={`${m.student_detailsPopup}`}>
                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <div className={`${m.imgbox}`}>
                                        {props?.teacherDetails?.teacher_picture == "" || props?.teacherDetails?.teacher_picture == null ?
                                            <ProfileAvatar name={props?.teacherDetails?.teacher_name} />
                                            : <img className={`${m.imgClass}`} src={props?.teacherDetails?.teacher_picture} alt="profile" />}
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Name:</label>
                                    <div className="form-control">{props?.teacherDetails?.teacher_name}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Highest Qualifications:</label>
                                    <div className="form-control">{props?.teacherDetails?.qualifications ?? "NA"}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Teaching Experience:</label>
                                    <div className="form-control">{`${props?.teacherDetails?.experience == "null" ? "NA" : props?.teacherDetails?.experience} years`} </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Country:</label>
                                    <div className="form-control">{props?.teacherDetails?.country}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Rating:</label>
                                    <div className="form-control">{props?.teacherDetails?.teacher_rating || <>NA</>}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Total Count of Review:</label>
                                    <div className="form-control">{props?.teacherDetails?.total_count_of_review}</div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={`${m.btnGroup}`}>
                        <button style={{ backgroundColor: "#375dbc", color: "#fff" }} className={`btn ${m.actionBtn}`} onClick={handleTeacherDetailsClose}>Close</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TeacherDetails;
