import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Login.module.scss";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { NavLink, useHistory } from "react-router-dom";
import { Col, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FaKey } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";
import google from "../../../images/google.svg";
import facebook from "../../../images/facebook.svg";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { RotatingLines } from "react-loader-spinner";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getDeviceToken } from "../../../firebase";

export default function Teacherlogin() {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  const auth = getAuth();

  const [fcmToken, setfcmToken] = useState('');
  getDeviceToken().then((token) => {
    setfcmToken(token)
  }).catch((err) => {
    console.error("Error retrieving token:", err);
  });

  const notify = (message: String) =>
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  const [show, setShow] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Login');

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 1000);
    }
  }, [onetimenotify])
  const navigate = useHistory();

  //------------------------------------ LOGIN API ------------------------------------//
  function loginteacher(postdata: any) {

    let formdata = new FormData();
    formdata.append('email', postdata.email);
    formdata.append('password', postdata.password);
    formdata.append('deviceToken', fcmToken)
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher-login`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.data.api_token) {
          if (response.data.statusCode == 200) {
            if (response.data.data.teacher_complete_profile_status == 0) {
              setTimeout(() => {
                navigate.push('/teacher/complete-profile', {
                  userId: response.data.data.id,
                  name: response.data.data.name
                });
              }, 2000);
              setTimenotify(response.data.message);
              setOnetimenotify(true)
            }
            else if (response.data.data.teacher_available_slot_status == 0) {
              setTimeout(() => {
                navigate.push('/teacher/info', {
                  userId: response.data.data.id,
                });
              }, 2000);
              setTimenotify(response.data.message);
              setOnetimenotify(true)
            }
            else if (response.data.data.teaching_details_status == 0) {
              setTimeout(() => {
                navigate.push('/teacher/availability', {
                  userId: response.data.data.id,
                });
              }, 2000);
              setTimenotify(response.data.message);
              setOnetimenotify(true)
            }
            else {
              localStorage.setItem("api_token", response.data.data.api_token);
              localStorage.setItem("userData", JSON.stringify(response.data.data));
              setTimeout(() => {
                navigate.push("/teacher/home");
              }, 2000);
              setTimenotify(response.data.message);
              setOnetimenotify(true);
            }
          }
        }
        else {
          setLoadingSpinner('Login')
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  //------------------------------------ LOGIN API GOOGLE/FACEBOOK ------------------------------------//
  function loginstudentSSO(name: any, email: any, type: number) {
    let formdata = new FormData();
    formdata.append('name', name);
    formdata.append('email', email);
    formdata.append('social_login_type', type.toString());
    formdata.append('usertype', "2");
    formdata.append('deviceToken', fcmToken);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/social-signup`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          if (response.data.data.teacher_complete_profile_status == 0) {
            setTimeout(() => {
              navigate.push('/teacher/complete-profile', {
                userId: response.data.data.id,
                name: response.data.data.name
              });
            }, 3000);
            setTimenotify(response.data.message);
            setOnetimenotify(true)
          }
          else {
            localStorage.setItem("api_token", response.data.data.api_token);
            localStorage.setItem("userData", JSON.stringify(response.data.data));
            setTimeout(() => {
              navigate.push("/teacher/home");
            }, 3000);
            setTimenotify(response.data.message);
            setOnetimenotify(true);
          }
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  const responseFacebook = (response: any) => {
    loginstudentSSO(response?.name, response?.email, 1)
  };

  const handleClick = () => {
    signInWithPopup(auth, provider)
      .then((result: any) => {
        const credential: any = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        loginstudentSSO(user?.displayName, user?.email, 2);
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage)
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <img src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img src={logo} className={`${cx.logo}`} />
            </Col>
          </div>

          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={`m-auto`}>
              <h5 className={`${st.themeColor}`}>Hello Again!</h5>
              <p className={`${st.fontColor}`}>Welcome back you’ve been missed!</p>
            </Col>

            <Col lg={4} className={`m-auto`}>
              <Form onSubmit={handleSubmit(loginteacher)} className={`${st.formFull}`}>
                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control type="email" placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    })}
                  />
                  {errors?.email?.type === "pattern" && (
                    <p style={{ color: "red" }}>Invalid email address</p>
                  )}
                  {errors?.email?.type === "required" && (
                    <p style={{ color: "red" }}>This field is required </p>
                  )}
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <div>
                    <div className="position-relative">
                      <Form.Control type={show ? "password" : "text"} placeholder="Password"
                        {...register("password", {
                          required: "This password is required",
                          minLength: {
                            value: 6,
                            message: "Please enter minimum 6 characters",
                          },
                        })}
                      />
                      {show ? (
                        <AiFillEyeInvisible
                          className={`${st.actionIcon}`}
                          onClick={() => {
                            setShow(false);
                          }}
                        />
                      ) : (
                        <AiFillEye
                          className={`${st.actionIcon}`}
                          onClick={() => {
                            setShow(true);
                          }}
                        />
                      )}
                    </div>
                    {errors?.password?.type === "minLength" && (
                      <p style={{ color: "red" }}>
                        Please enter minimum 6 characters
                      </p>
                    )}
                    {errors?.password?.type === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                    <div />

                  </div>
                  <Col lg={12} className="text-end">
                    <NavLink to="/teacher/forgot-password" className={`${st.forgotPassword}`}>
                      <FaKey className={`${st.icon}`} /> Forgot password?
                    </NavLink>
                  </Col>
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    <Button className={`btn ${st.actionBtn}`} type="submit">
                      {loadingSpinner}
                    </Button>
                  </Col>
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    <p className={`${st.themeColor} text-center mb-0 mt-4`}>Or Continue with</p>
                    <ul className={`${cx.socialSignup}`}>
                      <li>
                        <span onClick={handleClick}>
                          <img src={google} alt="google" />
                        </span>
                      </li>
                      <li>
                        <span>
                          <FacebookLogin
                            appId="3445293595716613"
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={responseFacebook}
                            render={(renderProps) => (
                              <img onClick={renderProps.onClick} src={facebook} />
                            )} />
                        </span>
                      </li>
                    </ul>
                    <p className={`${st.fontColor} text-center mt-3`}>Don't have an account? <NavLink className={`${st.themeColor} text-decoration-none`} to="/teacher/signup">Signup</NavLink></p>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
}