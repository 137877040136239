import React from 'react';
import { useState } from 'react';
import m from "./Modal.module.scss";
import { Modal, Button, Form } from 'react-bootstrap';
import { IoLogoWhatsapp } from 'react-icons/io';
import axios from 'axios';
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const CustomerSupport = (props: any) => {
    let localData = JSON.parse(localStorage.getItem("userData")!);
    let { show, handleCustomerSupportClose } = props;
    const [loadingSpinner, setLoadingSpinner] = useState<any>('Submit');
    const [contactSupports, setContactSupports] = useState({ message: "" });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (contactSupports?.message != '') {
            CustomerSupport();
        }
    };

    const notify = (message: String) =>
        toast(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });

    // ----------------------------- TEACHER CONTACT SUPPORT API -----------------------------//
    function CustomerSupport() {
        let formdata = new FormData();
        formdata.append("enroll_id", props?.id);
        formdata.append("message", contactSupports?.message);
        formdata.append("phone", localData?.phone);

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/teacher_contact_support`,
            headers: {
                Authorization: "Bearer" + localStorage.getItem("api_token"),
            },
            data: formdata,
        };
        setLoadingSpinner(
            <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="25"
                visible={true}
            />)
        axios(config)
            .then(function (response) {
                notify("Contact support addeded successfully");
                handleCustomerSupportClose();
                setLoadingSpinner('Submit')
            })
            .catch(function (error) {
                notify("Something went wrong, Please try again later");
                console.log(error)
                setLoadingSpinner('Submit')
            });
    }

    return (
        <>
            <ToastContainer limit={1} />
            <Modal show={show} onHide={handleCustomerSupportClose} className={`${m.customerSupport_popUp}`} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Contact Support</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="">
                            <Form.Control
                                as="textarea"
                                placeholder="Message"
                                style={{ height: "100px" }}
                                onChange={(e) => {
                                    setContactSupports((prev) => ({
                                        ...prev,
                                        message: e.target.value,
                                    }));
                                }}
                            />
                        </Form.Group>
                        <Button variant="primary" className={`${m.book_btn} btn`} type="submit">
                            {loadingSpinner}
                        </Button>
                    </Form>
                    <p className='mb-0'>
                        <a
                            href="https://api.whatsapp.com/send?phone=918440868293"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`${m.whatsapp_btn} btn`}
                        >
                            WhatsApp Support <IoLogoWhatsapp />
                        </a>

                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CustomerSupport