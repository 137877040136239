import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import { useForm } from 'react-hook-form';
import cx from "./Info.module.scss";
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import { Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import { RotatingLines } from "react-loader-spinner";

export default function Teacherinfo(props: any) {

  let localData = JSON.parse(localStorage.getItem("userData")!);
  const location: any = useLocation();

  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const navigate = useHistory();
  const [classData, setClassData] = useState<any>([]);
  const [subjectorcourses, setsubjectorcourses] = useState<any>([]);
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Submit');
  const [formLength, setFormLength] = useState<any>([{ class_or_category_id: "", subject_or_course_id: "", type: "", fees: "", currency_symbol: "" }])

  useEffect(() => {
    getallclscategory();
  }, [])

  let formdata = new FormData();

  // ----------------------------- GET ALL CLASS CATEGORY API------------------------------//
  function getallclscategory() {

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/get_all_class_subject_categories_course`,
      headers: {
        "Authorization": "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          let classcategories: any[] = [];
          response.data.data.categoryDetails?.forEach((subelement: any) => {
            classcategories.push({ extraData: subelement?.courseDetails, label: subelement?.name, value: subelement?.name, uniqueId: subelement?.id, type: 'categories', isDisabled: false })
          });
          response.data.data?.classDetails?.forEach((subelement: any) => {
            classcategories.push({ extraData: subelement?.subjectDetails, label: subelement?.class_name, value: `${subelement?.id + response.data.data.categoryDetails.length}`, uniqueId: subelement?.id, type: 'class', isDisabled: false })
          });
          setClassData(classcategories)
          localStorage.setItem("userData", JSON.stringify(response.data.data));

          // FIlter CLasss
          const filterClass = response.data.data.classDetails.filter((obj: any, index: any) =>
            response.data.data.classDetails.findIndex((event: any) => event.id == obj.id) == index
          );
          const filterItemArr: any = []
          filterClass.map((filterItem: any) => {
            filterItemArr?.push({ class: filterItem?.class_name, id: filterItem?.id });
          })
        }
      })
      .catch(function (error) {
        notify("Something went wrong");
      });
  }

  // ----------------------------- ADD TEACHER INFO ----------------------------- //
  function teacherInfo() {
    let formdata = new FormData();
    formdata.append('userId', location?.state?.detail);
    formdata.append('data', JSON.stringify(formLength))

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/enterteacherdetails`,
      headers: {
        "Authorization": "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/teacher/availability',
              state: { detail: location?.state?.detail, id: location?.state?.userId, timezone: location?.state?.timezone }
            });
          }, 2000);
        }
        else {
          setLoadingSpinner('Submit')
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  function handleClassCategoryStatus(eventclass: any, index: number) {
    setFormLength((prev: any) => {
      prev[index].class_or_category_id = eventclass?.uniqueId;
      prev[index].type = eventclass.type == "categories" ? 2 : 1;
      return prev
    })

    if (eventclass.type == "categories") {
      let extraData: any = []
      eventclass?.extraData.forEach((course: any) => {
        extraData?.push({ label: course?.name, value: course?.id, isDisabled: false })
      });
      setsubjectorcourses(extraData)
    } else {
      let extraData: any = []
      eventclass?.extraData.forEach((subject: any) => {
        extraData?.push({ label: subject?.title, value: subject?.id, isDisabled: false })
      });
      setsubjectorcourses(extraData)
    }
  }
  function handleSubjectCoursesStatus(event: any, index: number) {
    setFormLength((prev: any) => {
      prev[index].subject_or_course_id = event.value;
      return prev
    })
  }

  useEffect(() => {
    setFormLength(formLength)
  }, [formLength])


  return (
    <>
      <ToastContainer />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img src={logo} className={`${cx.logo}`} />
            </Col>
          </div>

          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={'m-auto'}>
              <h5 className={`${st.themeColor}`}>Teaching Information</h5>
              <p></p>
            </Col>

            <Col lg={4} className={'m-auto'}>
              <Form onSubmit={handleSubmit(teacherInfo)} className={`${st.formFull}`}>
                {
                  formLength?.map((item: any, index: number) => {
                    return (
                      <>
                        <Form.Group className={`${st.formField}`} controlId="">
                          <Select key={index}
                            onChange={(e: any) => { handleClassCategoryStatus(e, index) }}
                            options={classData}
                          />
                        </Form.Group>

                        <Form.Group className={`${st.formField}`} controlId="">
                          <Select key={index}
                            onChange={(e: any) => { handleSubjectCoursesStatus(e, index) }}
                            options={subjectorcourses}
                          />
                        </Form.Group>

                        <Form.Group className={`${st.formField}`} controlId="">
                          <Form.Control type="number" min={0} placeholder="Fee Per Class"
                            value={localData?.fees}
                            key={props?.fees}
                            onChange={
                              (e) => {
                                setFormLength((prev: any) => {
                                  prev[index].fees = e.target.value;
                                  return prev
                                })
                              }} />
                        </Form.Group>
                      </>
                    )
                  })
                }

                <Form.Group className={`${cx.formField}`}>
                  <Button className={`btn ${cx.grayBtn}`} onClick={() => {
                    const clonefield = formLength.slice();
                    const data = clonefield.splice(clonefield.length - 1, 1);
                    setFormLength(clonefield)
                  }}>
                    Remove
                  </Button>
                  <Button className={`btn ${cx.actionBtn}`} onClick={() => {
                    setFormLength((prev: any) => {
                      return [...prev, {
                        class_or_category_id: "", subject_or_course_id: "", type: "", fees: "", currency_symbol: ""
                      }]
                    })
                  }}>
                    Add
                  </Button>
                </Form.Group>

                <Col lg={12}>
                  <Button className={`btn ${cx.submitBtn}`}
                    onClick={teacherInfo}>
                    {loadingSpinner}
                  </Button>
                </Col>

              </Form>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
}