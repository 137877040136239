import React, { useState } from "react";
import { useCallback } from "react";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import axios from 'axios';
import cx from "../../pages/Student/Purchaseclass/Purchaseclass.module.scss";
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function RazorPayB(props: any) {
    let localData = JSON.parse(localStorage.getItem("userData")!);
    const Razorpay = useRazorpay();
    const [loadingSpinner, setLoadingSpinner] = useState<any>('Proceed to Pay');

    const notify = (message: String) =>
        toast(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    // --------------------------------STUDENT ENROLL API-----------------------------------//
    function payment() {
        let formdata = new FormData();
        formdata.append('amount', props.prize <= 1 ? 0 : props.prize);
        formdata.append('currency', props.currency);

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/studentenrollment-payment`,
            headers: {
                Authorization: "Bearer" + localStorage.getItem("api_token")!,
            },
            data: formdata,
        };
        setLoadingSpinner(
            <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="25"
                visible={true}
            />)
        axios(config)
            .then((response) => {
                console.log(response, "response986754")
                if (response.data.statusCode === 200) {
                    if (props?.prize === 0) {
                        props?.studentEnrollHandler();
                    } else {
                        handlePayment(response?.data?.data);
                    }
                } else {
                    setLoadingSpinner('Proceed to Pay');
                }
            })
            .catch((error) => {
                console.error(error);
                notify("Something went wrong, please refresh the page or try again later!");
                setLoadingSpinner('Proceed to Pay');
            });
    }

    const handlePayment = useCallback((orderId: string) => {
        const options: RazorpayOptions = {
            // key: "rzp_live_i2POkROBslsnRV", // =====> LIVE KEY
            key: "rzp_test_WTRnN6jBFwiQ0G", // =====> TEST KEY
            amount: props.prize,
            currency: props?.currency,
            name: "Guru At Home",
            description: "Test Transaction for Guru",
            image: "https://guruathome.org/wp-content/uploads/2021/06/cropped-ff3.png",
            order_id: orderId,
            handler: (res: any) => {
                props.studentEnrollHandler();
            },
            prefill: {
                name: localData?.name,
                email: localData?.email,
                contact: localData?.phone,
            },
            notes: {
                address: localData?.address,
            },
            theme: {
                color: "#294FAE",
            },
        };
        const rzpay = new Razorpay(options);
        rzpay.open();
    }, [Razorpay]);

    return (
        <>
            <ToastContainer limit={1} />
            <div className="App">
                <button className={`btn ${cx.proceedPay}`}
                    onClick={() => {
                        payment()
                    }}
                >{loadingSpinner}
                </button>
            </div>
        </>
    );
}