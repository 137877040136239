import React from "react";
import st from "../../../style.module.scss";
import cx from "./Helpsupport.module.scss";
import { NavLink } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { BiPhoneCall } from "react-icons/bi";
import { IoLogoWhatsapp } from "react-icons/io";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg1.svg";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";

export default function Helpsupport() {
  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.aboutUs}`}>
                  <h6>Help and Support</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
          </div>

          <div className={`container ${cx.contentBody} mt-3`}>
            <Form>
              <Form.Group className={`${st.formField}`} controlId="">
                <h5 className="mb-3">Help and Support</h5>
                <p
                  className={`${st.fontColor} text-left mt-2 mb-3`} style={{ fontSize: '15px' }}>
                  Lorem ipsum, or lapsus as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Fin bus Bono rum et Malo rum for use.
                </p>
                <div className={`${cx.socialIcon}`}>
                  <a href="mailto: guruathome4506@gmail.com"><GrMail /></a>
                  <a href="tel:918440868293"><BiPhoneCall /></a>
                  <a href="https://api.whatsapp.com/send?phone=918440868293"><IoLogoWhatsapp /></a>
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}