import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Teacher/Footer/Footer';
import st from "../../../style.module.scss";
import cx from "./Detail.module.scss";
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import ReactLoading from "react-loading";

const BasicDetail = () => {
  useEffect(() => {
    particularClass();
  }, [])

  let localData = JSON.parse(localStorage.getItem("userData")!);
  const [data, setData] = useState([]);
  const [demoData, setDemoData] = useState([]);
  const [loaderStatus, setloaderStatus] = useState<any>(<ReactLoading width={35} type="spin" color="blue" />);

  function particularClass() {
    let formdata = new FormData();
    formdata.append('teacher_id', localData?.id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/particular_teacher_classes`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response: any) {
        setData(response?.data?.data)
        setDemoData(response?.data?.demo)
        setloaderStatus(response?.data?.data?.length == 0 && response?.data?.demo?.length == 0 ? 'Slots not available' : '')
      })
      .catch(function (error) {
        console.log(error, "error")
      });
  }

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>

          <Container>
            <div className={`${cx.mainBox}`}>
              <div className={`${cx.headerTitle}`}>
                <h1>Slots Confirmation</h1>
              </div>
            </div>
            <Row>
              {data?.length === 0 && (
                <div className="d-flex justify-content-center">
                  {loaderStatus}
                </div>
              )}
              {data?.map((item: any) => {
                return (
                  <Col lg={6}>
                    {item?.pending_classes_count > 0 && <NavLink className={`${cx.classCard}`} to={`/teacher/basic-detail-list/${item?.student_id}&enroll`}>
                      <ul>
                        <li>
                          <span>
                            <h5>Student- <label className={`${st.themeColor}`}>{item?.student_name}</label></h5>
                          </span>
                          <span>No. Of Class- <label className={`${st.themeColor}`}> {item?.pending_classes_count} </label></span>
                        </li>
                        <li>
                          <span>
                            {item?.subject_name ? 'Subject' : 'Course'}- <label className={`${st.themeColor}`}>{item?.subject_name ?? item?.course_name}</label>
                          </span>
                          <span>{item?.class_name ? `Class` : `Category`}- <label className={`${st.themeColor}`}>{item?.class_name ?? item?.category_name}</label></span>
                        </li>
                      </ul>
                    </NavLink>}
                  </Col>
                )
              })
              }
            </Row>
            <Row>
              {demoData?.map((item: any) => {
                return (
                  <Col lg={6}>
                    <NavLink className={`${cx.classCard}`} to={`/teacher/basic-detail-list/${item?.student_id}&demo`}>
                      <button style={{ background: '#375dbc', color: "white", fontSize: '12px', padding: "5px" }} className="btn">demo</button>
                      <ul>
                        <li>
                          <span>
                            <h5>Student- <label className={`${st.themeColor}`}>{item?.student_name}</label></h5>
                          </span>
                          <span>No. Of Class- <label className={`${st.themeColor}`}> {item?.demo_classes_count} </label></span>
                        </li>
                        <li>
                          <span>
                            {item?.subject_name ? 'Subject' : 'Course'}- <label className={`${st.themeColor}`}>{item?.subject_name ?? item?.course_name}</label>
                          </span>
                          <span>{item?.class_name ? `Class` : `Category`}- <label className={`${st.themeColor}`}>{item?.class_name ?? item?.category_name}</label></span>
                        </li>
                      </ul>
                    </NavLink>
                  </Col>
                )
              })
              }
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default BasicDetail