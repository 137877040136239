import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Classes.module.scss";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Bottombar from "../../../components/Teacher/Bottombar/Bottombar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/Teacher/Footer/Footer";
import Header from "../../../components/Header/Header";
import ReactLoading from "react-loading";

export default function Teacherclasses() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useHistory();
  const [data, setData] = useState([]);

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    myClassTeachers();
  }, [])

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  // -------------------------------------------- MY CLASS API --------------------------------------------//
  function myClassTeachers() {

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/my_classes`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data) {
          setloaderStatus(<div>Classes Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          setData(response.data.data)
        }
      })
      .catch(function (error) {
        notify("Something went wrong, Please try again later. Thanks!");
      });
  }

  return (
    <>
      <Header />
      <ToastContainer />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.spaceBox}`}></div>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                </Col>
                <Col className={`col-8 text-center ${cx.headTitle}`}>
                  <h6>My Classes</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`container ${cx.classesMargin}`}>
            <Row>
              {data?.length == 0 && (
                <div className="d-flex justify-content-center">
                  {loaderStatus}
                </div>
              )}
              {data?.map((item: any, index: any) => {
                return (
                  <Col lg={4}>
                    <div className={`${cx.classesBox}`}>
                      <div className="mt-3">
                        <h3>{item?.display_date}</h3>
                        <div className={`${cx.classCard}`}>
                          <ul>
                            <li>
                              <span>Total Classes Taken</span>
                              <span className={`${cx.themeColor}`}>{item?.total_class} Class</span>
                            </li>
                            <li>
                              <span>Total Hours</span>
                              <span className={`${cx.themeColor}`}>{item?.total_hours} Hours</span>
                            </li>
                            <li>
                              <span>Total Students</span>
                              <span className={`${cx.themeColor}`}>{item?.total_student} Students</span>
                            </li>
                          </ul>
                          <Row>
                            <Col>
                              <Button className='btn mt-2'
                                onClick={() => {
                                  navigate.push("/teacher/view-more", {
                                    state: { details: item.class_end_date }
                                  })
                                }}>View More</Button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className={`pt-3 ${st.spaceBox}`}></div>
          <Bottombar />
        </div>
      </section >
      <Footer />
    </>
  );
}