import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Myearning.module.scss";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../../components/Teacher/Footer/Footer";
import Header from "../../../components/Header/Header";
import ReactLoading from "react-loading";

export default function Teachermyearning() {
  let localData = JSON.parse(localStorage.getItem("userData")!);
  useEffect(() => {
    myEarning();
  }, [])

  const [data, setData] = useState([]);
  const [total_amount, setTotal_amount] = useState('')

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  // ---------------------------MY EARNING API-------------------------- //
  function myEarning() {

    let formdata = new FormData();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teachermyearning`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        setData(response.data.data);
        setTotal_amount(response.data.total_amount)
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>

          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/teacher/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>My Earning</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>

          <div className={`${cx.amountRecieved}`}>
            <h5>Total Amount Received</h5>
            <div className={`${cx.amountBox}`}>
              <input type="text" value={localData?.currency.slice(4) + total_amount} disabled />
              <NavLink to="/teacher/payout">
                Withdraw
              </NavLink>
            </div>
          </div>

          <div className={`container ${cx.contentBody}`}>
            {data?.length == 0 && (
              <div className="d-flex justify-content-center">
                {loaderStatus}
              </div>
            )}
            <Row>
              {data?.map((item: any) => {
                return (
                  <Col lg={4}>
                    <div className={`${cx.contentBody}`}>
                      <div className={`${cx.classCard}`}>
                        <ul>
                          <li>
                            <span><h5>Student Name-</h5></span>
                            <span><h5>{item?.student_name}</h5></span>
                          </li>
                          <li>
                            <span>{item?.class_name ? "Class" : "Category"} - {item?.class_name || item?.category_name}</span>
                            <span>Rate - {localData?.currency.slice(4)}{item?.fees}/hr</span>
                          </li>
                          <li>
                            {/* <span>Total Classes - {item?.total_class_count}</span> */}
                            <span>{item?.subject_name ? "Subject" : "Course"} - {item?.subject_name || item?.course_name}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}