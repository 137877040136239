import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Classes.module.scss";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Col, Row, Form, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { MdOutlineClose, MdOutlineInfo } from "react-icons/md";
import { BsStarFill, BsStar, BsStarHalf } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { AiFillEye } from "react-icons/ai";
import { RotatingLines } from "react-loader-spinner";
import { IoLogoWhatsapp } from "react-icons/io";
import TeacherDetails from "../../../components/Modal/Student/TeacherDetails";
import DemoDetailM from "../../../components/Modal/Student/DemoDetailM";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import SwiperCore from 'swiper';
import Bottombar from "../../../components/Student/Bottombar/Bottombar";
import ProfileAvatar from "../../../Custom_Components/ProfileAvatar/ProfileAvatar";
SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function Classes() {
  const location: any = useLocation();
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  useEffect(() => {
    handleSlideChange({ realIndex: location?.state?.index ?? 0 });
  }, [])
  const handleSlideChange = (e: any) => {
    const activeIndex = e?.realIndex;
    switch (activeIndex) {
      case 0:
        upcomingClasses();
        break;
      case 1:
        demoClasses();
        break;
      case 2:
        completeClasses("Classes");
        break;
      default:
        break;
    }
  };

  const [showTeacherDetails, setTeacherDetailsShow] = useState(false);
  const handleTeacherDetailsClose = () => setTeacherDetailsShow(false);
  const handleTeacherDetailsShow = () => setTeacherDetailsShow(true);

  const [showDemoDetails, setDemoDetailsShow] = useState(false);
  const handleDemoDetailsClose = () => setDemoDetailsShow(false);
  const handleDemoDetailsShow = () => setDemoDetailsShow(true);

  let localData = JSON.parse(localStorage.getItem("userData")!);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow: any = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleShow7 = () => setShow7(true);
  const handleClose7 = () => setShow7(false);
  const [data, setData] = useState<any[]>([]);
  const [teacher_assign, setTeacher_assign] = useState([]);
  const [demo_class_data, setDemo_class_data] = useState([]);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Submit');
  const [teacherDetails, setTeacherDetails] = useState([]);
  const [checkClass, setCheckClass] = useState<any>("");

  const navigate = useHistory();

  const [loaderStatus, setloaderStatus] = useState(<ReactLoading type="cylon" color="blue" />);

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const [forSearchData, setForSearchData] = useState<any>();
  const [contactSupports, setContactSupports] = useState<any>({ teacher_id: "", enroll_id: "", message: "" });
  const [feedback, setFeedback] = useState<any>({ student_apply_for_demo_id: "", teacher_id: "", rating: "", message: "", enroll_id: "" });
  const [enroll_id, setEnroll_id] = useState("");
  const [student_enroll_schedule_id, setStudent_enroll_schedule_id] = useState("");
  const [upcommingENrollID, setupcommingENrollID] = useState("");
  const [stars, setStars] = useState<any>([{ point: 0 }, { point: 0 }, { point: 0 }, { point: 0 }, { point: 0 }]);
  const [teacher_id, setTeacher_id] = useState('');
  const [url, seturl] = useState('');
  const [id, setId] = useState('');
  const [subjectName, setSubjectName] = useState('');
  const [plans, setPlans] = useState('');
  const [subject_details, setsubject_details] = useState('');
  const [classId, setclassId] = useState<any>('');
  const [subjectId, setsubjectId] = useState<any>('');
  const [categoryId, setCategoryId] = useState<any>('');
  const [courseId, setcourseId] = useState<any>('');
  const [courseDetails, setCourseDetails] = useState<any>('');
  const [rescheduleTime, setstart_time] = useState('');
  const [matchCurrency, setMatchCurrency] = useState('');
  const [studentapplyfordemoID, setStudentApplyForDemoId] = useState('');
  const [date, setDate] = useState('');
  const [completeData, setCompleteDate] = useState<any[]>([]);
  const [pdf, setPdf] = useState<any>([]);

  // -------------------------------------------- COMPLETED CLASS API -------------------------------------------//
  function completeClasses(Classes: any) {

    let formdata = new FormData();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/get_EnrollConpletedClasses`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
        "Content-Type": "application/octet-stream"
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        setCompleteDate(response?.data?.data);
        seturl(response?.data?.data[0]?.url)

        if (response.data.data) {
          setloaderStatus(<div>Class Not Found ...</div>);
        }
        navigate.push({
          state: "complete"
        });
      })
      .catch(function (error) {
        console.log(error, "Err")
      });
  }

  // -------------------------------------------- UPCOMING CLASS API --------------------------------------------//
  function upcomingClasses() {

    let formdata = new FormData();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/get_UpcomingClasses`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        navigate.push({
          state: "upcomming"
        });
        if (response.data.data) {
          setloaderStatus(<div> Class Not Found ...</div>);
        }
        setData(response.data.data);
        // setFilteredData(response.data.data)
        response.data.data?.forEach((item: any) => {
          setstart_time(item?.start_time)
          setDate(item?.select_date)
        })
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  // -------------------------------------------- DEMO CLASS API ------------------------------------------------//
  function demoClasses() {

    let formdata = new FormData();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/get_demo_class_done`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Class Not Found ...</div>);
        }
        setData(response.data.data)
        setDemo_class_data(response.data.demo_class_data)
        setTeacher_assign(response.data.teacher_assign)
        // navigate.push({
        //   state: "demo"
        // });
        // setTeacher_available_slot(response.data.teacher_available_slot)
        seturl(response.data.url)
        response.data.teacher_assign.map((item: any, index: number) => {
          item?.plans[0]?.Planfrequencys?.map((e: any) => {
            // console.log(e, "emekyahaiii")
            setMatchCurrency(e?.currency)
          })
          // console.log(item, "item10999")
          // setdemoChatIcon(item?.chat_lenght?.student_length)
          setSubjectName(item?.subject_name);
          setPlans(item?.plans);
          setsubject_details(item?.subject_details);
          setclassId(item?.class_id);
          setsubjectId(item?.subject_id);
          setCategoryId(item?.category_id);
          setcourseId(item?.course_id);
          setCourseDetails(item?.course_details);
          setTeacher_id(item?.teacher_id);
        })
      })
      .catch(function (error) {
        console.log(error)
        notify('Something went wrong. Please refresh the page or try again later');
      });
  }

  // -------------------------------------------- CONTACT SUPPORT API -------------------------------------------//
  function contactSupport() {
    let formdata = new FormData();
    formdata.append('student_id', localData?.id);
    formdata.append('teacher_id', contactSupports.teacher_id);
    formdata.append('enroll_id', contactSupports.enroll_id);
    formdata.append('message', contactSupports.message);
    formdata.append('phone', localData?.phone);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/student_contact_support`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          handleClose();
          setContactSupports({ contactSupports: "" });
          setTimenotify(response.data.message);
          setContactSupports((prev: any) => {
            return { ...prev, message: "" }
          })
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/student/classes',
              state: { detail: response.data.id }
            });
          }, 3000);
          setLoadingSpinner('Submit')
        }
        else {
          setLoadingSpinner('Submit')
          handleClose();
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  // -------------------------------------------- DEMO CLASS FEEDBACK API ---------------------------------------//
  function demoClsFeedback() {

    let formdata = new FormData();
    formdata.append('student_apply_for_demo_id', feedback.student_apply_for_demo_id);
    formdata.append('teacher_id', feedback.teacher_id);
    formdata.append('rating', stars?.reduce((acc: number, cur: any) => acc + cur.point, 0));
    formdata.append('message', feedback.message);
    formdata.append('enroll_id', feedback?.enroll_id ? feedback?.enroll_id : 'null');

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/demo_class_feedback`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        demoClasses();
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          handleClose2();
          setTimenotify(response.data.message);
          setLoadingSpinner('Submit');
          setOnetimenotify(true);
          // completeClasses(Classes);
          // setFeedback(JSON.stringify(stars))
          if (location?.state == "demo") {
            demoClasses()
          } else {
            completeClasses('Classes')
          }
          setFeedback((prev: any) => {
            return { ...prev, message: "" }
          })
          setStars([{ point: 0 }, { point: 0 }, { point: 0 }, { point: 0 }, { point: 0 }])
          // setTimeout(() => {
          //   navigate.push({
          //     pathname: '/student/classes',
          //   });
          // }, 1000);
          if (response.data.data.rating <= "2" && location?.state == "demo") {
            handleShow6();
          }
        }
        else {
          setLoadingSpinner('Submit')
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        setLoadingSpinner('Submit');
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  // -------------------------------------------- UPCOMING CLASS CANCEL API ------------------------------------//
  function upcomingCancelClasses(enroll_id: any, student_enroll_schedule_id: any, studentapplyfordemo_id: any, teacher_id: any, rescheduleTime: any, date: any) {

    let formdata = new FormData();
    formdata.append('enroll_id', enroll_id);
    formdata.append('student_enroll_schedule_id', student_enroll_schedule_id);
    formdata.append('studentapplyfordemo_id', studentapplyfordemo_id);
    formdata.append('teacher_id', teacher_id);
    formdata.append('start_time', rescheduleTime);
    formdata.append('start_date', date);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/get_UpcomingCancelClasses`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          handleClose7();
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  // -------------------------------------------- DEMO CLASS CANCEL API -----------------------------------------//
  function demoCancelClasses(id: any) {

    let formdata = new FormData();
    formdata.append('id', id);
    formdata.append('teacher_id', teacher_id);
    formdata.append('start_time', rescheduleTime);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/student_demo_class_cancel`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        // setTimenotify(error.response.data.message);
      });
  }

  // -------------------------------------------- HOMEWORK UPLOAD SUBMIT API ------------------------------------//
  function submitHomework(file: any) {
    // file.preventDefault()
    let formdata = new FormData();
    formdata.append('student_id', localData?.id);
    formdata.append('teacher_student_assignment_id', id);
    formdata.append('enroll_id', enroll_id);
    formdata.append('teacher_id', teacher_id);
    formdata.append('student_enroll_schedule_id', student_enroll_schedule_id);
    formdata.append('assignment', file.target.files[0]);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/student_homework_submit`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          handleClose3();
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        if (id == "") {
          notify("You can't upload your homework before teacher");
        } else {
          notify("Something went wrong, please try again later");
        }
      });
  }

  // -------------------------------------------- DEMO JOIN API ------------------------------------//
  function joinDemo(demo_id: any, class_url: any) {
    let formdata = new FormData();
    formdata.append('demo_id', demo_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/join_demo_class`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.status == false) {
          window?.open(`//${class_url}`);
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // -------------------------------------------- UPCOMMING JOIN API ------------------------------------//
  function joinUpcomming(upcommingENrollID: any, class_url: any) {
    let formdata = new FormData();
    formdata.append('schedule_id', upcommingENrollID);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/join_enroll_class`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.status == false) {
          window?.open(`//${class_url}`);
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  const RatingStar = (star: any, num: number) => {
    for (let i = 0; i < stars.length; i++) {
      if (i < num) {
        setStars((prev: any) => {
          prev[i].point = 1
          return [...prev]
        })
      } else if (i == num) {
        setStars((prev: any) => {
          prev[i].point = star
          return [...prev]
        })
      } else {
        setStars((prev: any) => {
          prev[i].point = 0
          return [...prev]
        })
      }
    }
  };

  // var today: any = new Date();
  // let dd = String(today.getDate()).padStart(2, '0');
  // let mm = String(today.getMonth() + 1).padStart(2, '0');
  // let yyyy = today.getFullYear();
  // today = yyyy + '-' + mm + '-' + dd;

  useEffect(() => {
    if (location?.state == "Assignment" || location?.state == "Enroll Class End" || location?.state == "Class Ended") {
      completeClasses("Classes");
    } else if (location?.state == "Class Start Now" || location?.state == "Demo Class Start Now") {
      demoClasses();
    } else if (location?.state == "Credites" || location?.state == "Enroll Class Start Now" ||
      location?.state == "Enroll Reschedule Class" || location?.state == "Purchase Creadit" ||
      location?.state == "upcomming" || location?.state == null || location?.state == "Join Now") {
      upcomingClasses();
    }
  }, [])

  useEffect(() => {
    if (location?.state === "upcomming") {
      upcomingClasses();
    }
  }, [])

  function tConvert(time: any) {
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time?.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  // -------------------------------------------- CHECK CLASS START API -----------------------------------------//
  function check_class_start(student_enroll_schedule_id: any, type: any) {

    let formdata = new FormData();
    formdata.append('type', type);
    formdata.append("schedule_id", student_enroll_schedule_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/check_class_start`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 400) {
          setTimenotify(response.data.message);
          setCheckClass(response?.data?.status)
          setOnetimenotify(true);
        } else {
          setTimenotify(response.data.message);
          setCheckClass(response?.data?.status)
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        console.log(error)
      });
  }

  return (
    <>
      <ToastContainer limit={1} />
      <Header />
      <section className={`${cx.slider_mainSection} ${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Classes</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>

          <div className="container">
            <div>
              <div className={`${cx.swiperSelect_main}`}>
                <div className={`${cx.swiperSelect_details}`}>
                  <Swiper
                    initialSlide={location?.state?.index}
                    onSwiper={setThumbsSwiper}
                    // onActiveIndexChange={setvisibleSlidesIndexes}
                    spaceBetween={10}
                    navigation={false}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                    onSlideChange={handleSlideChange}
                  >
                    <SwiperSlide>
                      <div>
                        <Row>
                          {data?.length == 0 && (
                            <div className="d-flex justify-content-center vh-100">
                              {loaderStatus}
                            </div>
                          )}
                          {data?.map((item: any) => {
                            let date: any = new Date().toISOString(); //THIS ONE 
                            let newDate = date.split("T");
                            let getDate = newDate[0]
                            let getTime1 = newDate[1]?.slice(0, 8);
                            let final = item?.select_date + " " + item?.start_time
                            let finalUtcTime = getDate + " " + getTime1
                            let localTime = new Date(`${item?.select_date} ${item?.start_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
                            let splitTime = localTime.split(',');
                            let rescheduleTime = splitTime[1]
                            let endTIme = new Date(`${item?.select_date} ${item?.end_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
                            let splitdate = endTIme?.split(',');
                            let date1: any = new Date(finalUtcTime);
                            let date2: any = new Date(final);
                            let dif: any = Math.abs(date2 - date1) / 1000;
                            let link = item?.zoom_link || item?.class_url;
                            return (
                              <Col lg={6} className="my-3">
                                <div className={`${cx.classCard}`}>
                                  <ul>
                                    {/* <li>
                                      <span><h5><button onClick={() => {
                                        handleTeacherDetailsShow();
                                        setTeacherDetails(item);
                                      }}>
                                        {item?.teacher_name}
                                      </button>
                                      </h5></span>
                                      <span className={`${st.themeColor}`}>Date - {item?.select_date}</span>
                                    </li> */}
                                    <li className="mb-1 align-items-end">
                                      <span
                                        onClick={() => {
                                          handleTeacherDetailsShow();
                                          setTeacherDetails(item);
                                        }}
                                      >
                                        <div className={`${cx.profileName}`}>
                                          {item?.teacher_picture == "" || item?.teacher_picture == null ?
                                            <ProfileAvatar name={item?.teacher_name} />
                                            : <img className={`${cx.imgClass}`} src={item?.teacher_picture} />}
                                          <div className={`${cx.profileName_details}`}>
                                            <h5>{item?.teacher_name}</h5>
                                          </div>
                                        </div>
                                      </span>
                                      <span>
                                        {item?.approve_class == "Pending" ? <button className="btn bg-warning">Pending</button> : <button className="btn bg-success">Approved</button>}
                                      </span>
                                    </li>
                                    <li>
                                      <span className={`${st.themeColor}`}>Date - {splitTime[0] == "Invalid Date" ? 'yy-mm-dd' : splitTime[0]}</span>
                                    </li>
                                    <li>
                                      <span>{item?.subject_name ? "Subject" : "Course"} - {item?.subject_name || item?.course_name}</span>
                                      <span>{item?.class_name ? "Class" : "Category"} - {item?.class_name || item?.category_name}</span>
                                    </li>
                                    <li>
                                      <span>Start Time - <br /> {item?.select_date == "yy-mm-dd" ? "hh-mm" : splitTime[1]}</span>
                                      <span>End Time - <br /> {item?.select_date == "yy-mm-dd" ? "hh-mm" : splitdate[1]}</span>
                                    </li>
                                    <li>
                                      <span>Class Topic - </span>
                                      <span>{item?.class_topic}</span>
                                    </li>
                                    {/* <li>
                                      <span>Class Url-</span>
                                      <span>
                                        <a href={item?.zoom_link ? `//${item?.zoom_link}` : "Not Available"} >{item?.zoom_link ??.slice(0, 30) ? <button className="btn"
                                          disabled={item?.cancel_status == 1 || item?.class_end_status == 1 || item?.class_start_status == 1 ? true : false}  >Join</button> : "Not Available"}</a>
                                      </span>
                                    </li> */}
                                    <li>
                                      <span>Class Url -</span>
                                      <Button className={`${cx.linkBtn}`}
                                        // disabled={item?.cancel_status == 1 || item?.class_end_status == 1 || item?.class_start_status == 0 ? true : false}
                                        onClick={() => {
                                          // if (student_demo_class_cancel == 1) {
                                          //   setDisable(true);
                                          // }
                                          // if (item?.class_start_status == 1 && item?.class_end_status == 0) {
                                          //   <a target="_blank" href={item?.zoom_link} >Join</a>
                                          // }
                                          // else if (item?.class_start_status == 0) {
                                          //   notify("Teacher hasn't started the class yet");
                                          // }
                                          // else {
                                          //   notify("Class is already ended");
                                          // }
                                          // joinUpcomming(item?.student_enroll_schedule_id, item?.zoom_link || item?.class_url);
                                          if (link && link.startsWith('https://')) {
                                            link = link.replace('https://', '');
                                          }
                                          joinUpcomming(item?.student_enroll_schedule_id, link);
                                        }}>Join
                                        {/* {upcomingStatus === false ? <a target="_blank" href={`//${item?.zoom_link || item?.class_url}`}>Join</a> : "Join"} */}
                                      </Button>
                                    </li>
                                  </ul>
                                  <Row>
                                    <Col>
                                      <Button className='btn'
                                        disabled={item?.cancel_status == 1 || item?.select_date === "yy-mm-dd" || item?.class_end_status == 1 || item?.class_start_status == 1 ? true : false}
                                        onClick={() => {
                                          // if (cancel_status == 1) {
                                          //   setDisable(true)
                                          // }
                                          check_class_start(item?.student_enroll_schedule_id, 'enroll')
                                          setstart_time(item?.start_time);
                                          setDate(item?.select_date);
                                          handleShow7();
                                          setupcommingENrollID(item?.student_enroll_schedule_id);
                                          setEnroll_id(item?.enroll_id);
                                          setStudentApplyForDemoId(item?.student_apply_for_demo_id);
                                          setTeacher_id(item?.teacher_id);
                                          setStudent_enroll_schedule_id(item?.student_enroll_schedule_id);
                                          // upcomingCancelClasses(
                                          //   item?.enroll_id,
                                          //   item?.student_enroll_schedule_id,
                                          //   item?.student_apply_for_demo_id,
                                          //   item?.teacher_id,
                                          //   rescheduleTime
                                          // )
                                          setTimeout(() => {
                                            upcomingClasses();
                                          }, 2000);
                                          handleShow4();
                                        }}
                                      >Cancel</Button>
                                    </Col>
                                    <Col>
                                      <button className='btn'
                                        disabled={dif <= 10800 ? true : false || item?.cancel_status == 1 || item?.class_end_status == 1 || item?.class_start_status == 1 ? true : false}
                                        onClick={() => {
                                          // if (cancel_status == 1) {
                                          //   setDisable(true)
                                          // }
                                          navigate.push("/student/Reschedule", {
                                            details: item,
                                            teachersId: item?.teacher_id,
                                            start_time: rescheduleTime,
                                            student_enroll_schedule_id: item?.student_enroll_schedule_id,
                                            type: "enroll"
                                          })
                                        }}>{item?.select_date === "yy-mm-dd" ? "Book" : "Reschedule"}</button>
                                    </Col>
                                    <Col lg={12}>
                                      <Button className='btn mb-0'
                                        onClick={() => {
                                          setContactSupports({
                                            teacher_id: item?.teacher_id,
                                            enroll_id: item?.enroll_id,
                                          })
                                          handleShow();
                                        }}
                                      >Contact Support</Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div>
                        <Row>
                          {(teacher_assign?.length === 0 && demo_class_data?.length === 0 && data?.length === 0) && (
                            <div className="d-flex justify-content-center vh-100">
                              {loaderStatus}
                            </div>
                          )}
                          {teacher_assign?.map((item: any) => {
                            let localTime = new Date(`${item?.utc_date}`).toLocaleString("en-US", { timeZone: localData?.timezone });
                            let splitDateTime = localTime.split(",");
                            let link = item?.zoom_link || item?.class_url;
                            return (
                              <Col lg={4} className="my-3">
                                <div className={`${cx.classCard}`}>
                                  <ul>
                                    {/* <li>
                                      <span onClick={() => {
                                        handleDemoDetailsShow();
                                        setTeacherDetails(item);
                                      }}><h5>{item?.teacher_name}</h5></span>
                                      <span className={`${st.themeColor}`}>Date - {splitDateTime[0]}</span>
                                    </li> */}
                                    <li className="mb-1 align-items-end">
                                      <span
                                        onClick={() => {
                                          handleDemoDetailsShow();
                                          setTeacherDetails(item);
                                        }}
                                      >
                                        <div className={`${cx.profileName}`}>
                                          {item?.profile_img == "" || item?.profile_img == null ?
                                            <ProfileAvatar name={item?.teacher_name} />
                                            : <img className={`${cx.imgClass}`} src={item?.profile_img} />}
                                          <div className={`${cx.profileName_details}`}>
                                            <h5>{item?.teacher_name}
                                            </h5>
                                            {/* <button className="btn">View Profile</button> */}
                                          </div>
                                        </div>
                                      </span>
                                      <span>
                                        {item?.demo_class_approve == "Pending" ? <button className="btn bg-warning">Pending</button> : <button className="btn bg-success">Approved</button>}
                                      </span>
                                    </li>
                                    <li>
                                      <span className={`${st.themeColor}`}>Date - {splitDateTime[0]}</span>
                                    </li>
                                    <li>
                                      <span>Subject - {item?.subject_name ? item?.subject_name : item?.course_name}</span>
                                      <span>Class - {item?.class_name ? item?.class_name : item?.category_name}</span>
                                    </li>
                                    <li>
                                      <span>Time - {splitDateTime[1]}</span>
                                      <span style={{ color: 'var(--Main)', fontWeight: '600' }}>Class Rate- {matchCurrency == localData?.currency?.slice(0, 3) ? localData?.currency?.slice(0, 3) : 'USD'} {item?.start_price_range}-{item?.end_price_range} </span>
                                    </li>
                                    <li>
                                      <span>Preferred topic - {item?.preferred_topic ?? "NA"}</span>
                                    </li>
                                    <li>
                                      <span>Class Url -</span>
                                      <Button className={`${cx.linkBtn}`}
                                        disabled={item?.student_demo_class_cancel == 1 ? true : false}
                                        onClick={() => {
                                          // if (student_demo_class_cancel == 1) {
                                          //   setDisable(true);
                                          // }
                                          // if (item?.class_start_status == 1 && item?.class_end_status == 0) {
                                          //   <a href={`${item?.zoom_link}`} >Join</a>
                                          // }
                                          // else if (item?.class_start_status == 0) {
                                          //   notify("Teacher hasn't started the class yet");
                                          // }
                                          // else {
                                          //   notify("Class is already ended");
                                          // }
                                          if (link && link.startsWith('https://')) {
                                            link = link.replace('https://', '');
                                          }
                                          joinDemo(item?.studentapplyfordemo_id, link);
                                        }}>Join
                                        {/* {demoStatus == false ? <a target="_blank" href={`//${item?.zoom_link || item?.class_url}`}>Join</a> : "Join"} */}
                                      </Button>
                                    </li>
                                  </ul>
                                  <Row>
                                    <Col>
                                      <Button className='btn'
                                        disabled={item?.student_demo_class_cancel == 1 || item?.demo_feedback == 1 ? true : false}
                                        onClick={() => {
                                          if (item?.class_end_status === 0) {
                                            demoCancelClasses(
                                              item?.studentapplyfordemo_id
                                            )
                                          }
                                          else {
                                            setFeedback({
                                              student_apply_for_demo_id: item?.studentapplyfordemo_id,
                                              teacher_id: item?.teacher_assign_id,
                                            })
                                            handleShow2();
                                          }
                                        }
                                        }>{item?.class_end_status === 0 ? "Cancel" : "Feedback"}
                                      </Button>
                                    </Col>
                                    <Col>
                                      <Button className='btn'
                                        disabled={item?.student_demo_class_cancel == 1 ? true : false}
                                        onClick={() => {
                                          // if (student_demo_class_cancel == 1) {
                                          //   setDisable(true);
                                          // }
                                          if (item?.class_end_status === 0) {
                                            navigate.push("/student/Reschedule", {
                                              teacher_id: item?.teacher_assign_id,
                                              class_rate: item?.start_price_range,
                                              teacher_name: item?.teacher_name,
                                              subject_name: item?.subject_name,
                                              class_name: item?.class_name,
                                              course_name: item?.course_name,
                                              category_name: item?.category_name,
                                              student_booking_id: item?.id,
                                              utc_time: splitDateTime[1],
                                              studentapplyfordemo_id: item?.studentapplyfordemo_id,
                                              type: "demo"
                                            })
                                          }
                                          else {
                                            navigate.push("/student/Enroll", {
                                              item: item,
                                              student_apply_for_demo_id: item?.student_apply_for_demo_id,
                                              no_of_class: item?.no_of_class,
                                              teacher_id: item?.teacher_id,
                                              class_rate: item?.class_rate,
                                              coupon_code: item?.coupon_code,
                                              actual_amount: item?.actual_amount,
                                              gst_amount: item?.gst_amount,
                                              amount: item?.amount,
                                              discount_amount: item?.discount_amount,
                                              amount_to_pay: item?.amount_to_pay,
                                              teacher_name: item?.teacher_name,
                                              subject_name: item?.subject_name,
                                              class_name: item?.class_name,
                                            })
                                          }
                                        }}>{item?.class_end_status === 0 ? "Reschedule" : "Enroll"}
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            );
                          })}
                          {data?.map((item: any) => {
                            return (
                              <Col lg={4}>
                                <div className={`${cx.classCard}`}>
                                  <ul>
                                    <li>
                                      <span><h5>{item?.teacher_name}</h5></span>
                                      <span className={`${st.themeColor}`}>Date - {item?.select_date}</span>
                                    </li>
                                    <li>
                                      <span>{item?.subject_name ? "Subject" : "Course"} - {item?.subject_name ? item?.subject_name : item?.course_name}</span>
                                      <span>{item?.class_name ? "Class" : "Category"} - {item?.class_name ? item?.class_name : item?.category_name}</span>
                                    </li>
                                    <li>
                                      <span>Time - {item?.select_time}</span>
                                      <span style={{ color: 'var(--Main)', fontWeight: '600' }}>Class Rate - {localData?.currency?.slice(4)}{item?.start_price_range}-{item?.end_price_range}</span>
                                    </li>
                                    <li>
                                      <span>Class Url -</span>
                                      <span>
                                        <NavLink to="#">{item?.zoom_link ? item?.zoom_link : "Not Available"} </NavLink>
                                      </span>
                                    </li>
                                  </ul>
                                  <Row>
                                    <Col>
                                      <Button className='btn'
                                        disabled={item?.cancel_status == 1 ? true : false}
                                        onClick={() => {
                                          // if (cancel_status == 1) {
                                          //   setDisable(true)
                                          // }
                                          upcomingCancelClasses(item?.enroll_id,
                                            item?.student_enroll_schedule_id,
                                            item?.studentapplyfordemo_id,
                                            item?.teacher_id,
                                            rescheduleTime,
                                            date);
                                          setTimeout(() => {
                                            demoClasses()
                                          }, 2000);
                                        }}>Cancel</Button>
                                    </Col>
                                    <Col>
                                      <Button className='btn'
                                        disabled={item?.cancel_status == 1 ? true : false}
                                        onClick={() => {
                                          // if (cancel_status == 1) {
                                          //   setDisable(true)
                                          // }
                                          navigate.push("/student/Reschedule", {
                                            data: item,
                                            teacher_availabilty_of_slots_details_id: item?.teacher_availabilty_of_slots_details_id,
                                            student_enroll_schedule_id: item?.student_enroll_schedule_id,
                                            student_apply_for_demo_id: item?.student_apply_for_demo_id,
                                            no_of_class: item?.no_of_class,
                                            teacher_id: item?.teacher_id,
                                            class_rate: item?.class_rate,
                                            coupon_code: item?.coupon_code,
                                            actual_amount: item?.actual_amount,
                                            gst_amount: item?.gst_amount,
                                            amount: item?.amount,
                                            discount_amount: item?.discount_amount,
                                            amount_to_pay: item?.amount_to_pay,
                                            teacher_name: item?.teacher_name,
                                            subject_name: item?.subject_name,
                                            class_name: item?.class_name,
                                          })
                                        }}>Reschedule
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            );
                          })}
                          {demo_class_data?.map((item: any) => {
                            const dateString = item?.studen_apply_demo_class_data[0]?.select_date ? item?.studen_apply_demo_class_data[0]?.select_date : "Not Available";
                            const parts = dateString.split("-");
                            const formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
                            return (
                              <Col lg={4} className="my-3">
                                <div className={`${cx.classCard}`}>
                                  <ul>
                                    <li>
                                      <span><h5>{item?.teacher_name ? item?.teacher_name : <>Top Tutor Shortlisted...</>}</h5></span>
                                      <span className={`${st.themeColor}`}>Date - {formattedDate}</span>
                                    </li>
                                    <li>
                                      <span>{item?.subject_name ? "Subject" : "Course"}- {item?.subject_name ? item?.subject_name : item?.course_name}</span>
                                      <span>{item?.class_name ? "Class" : "Category"}- {item?.class_name ? item?.class_name : item?.category_name}</span>
                                    </li>
                                    <li>
                                      <span>Time - {tConvert(item?.select_time ? item?.select_time : "Not Available")}</span>
                                      <span style={{ color: 'var(--Main)', fontWeight: '600' }}>Class Rate - {localData?.currency?.slice(4)}{item?.start_price_range}-{item?.end_price_range}</span>
                                    </li>
                                    <li>
                                      <span>Preferred topic - {item?.studen_apply_demo_class_data?.map((topic: any) => topic?.preferred_topic)}</span>
                                    </li>
                                    <li>
                                      <span>Class Url - </span>
                                      <Button className={`${cx.linkBtn}`} disabled>
                                        {item?.zoom_link ? item?.zoom_link : "Join"}
                                      </Button>
                                    </li>
                                  </ul>
                                  <Row>
                                    <Col lg={12}>
                                      <NavLink className='btn' to="#"
                                        onClick={() => {
                                          demoCancelClasses(item?.id)
                                          setTimeout(() => {
                                            demoClasses()
                                          }, 2000);
                                        }}
                                      >Cancel</NavLink>
                                    </Col>
                                    <Col>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            );
                          })}

                        </Row>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div>
                        <Row>
                          {completeData?.length == 0 && (
                            <div className="d-flex justify-content-center vh-100">
                              {loaderStatus}
                            </div>
                          )}
                          {completeData?.map((item: any) => {
                            // let date: any = new Date().toISOString(); //THIS ONE
                            // let newDate = date.split("T");
                            // let getDate = newDate[0]
                            // let getTime1 = newDate[1]?.slice(0, 8)
                            // let final = item?.select_date + " " + item?.start_time
                            // let finalUtcTime = getDate + " " + getTime1
                            let localTime = new Date(`${item?.select_date} ${item?.start_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
                            let splitTime = localTime?.split(',');
                            let endTIme = new Date(`${item?.select_date} ${item?.end_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
                            let splitEndTime = endTIme?.split(',');
                            // let date1: any = new Date(finalUtcTime);
                            // let date2: any = new Date(final);
                            // let dif: any = Math.abs(date2 - date1) / 1000;
                            return (
                              <Col lg={4} className="my-3">
                                <div className={`${cx.classCard}`}>
                                  <ul>
                                    <li>
                                      <span><h5>{item?.teacher_name}</h5></span>
                                      <span className={`${st.themeColor}`}>Date - {localTime?.slice(0, 10).split(",")}</span>
                                    </li>
                                    <li>
                                      <span>{item?.subject_name ? "Subject" : "Course"} - {item?.subject_name || item?.course_name}</span>
                                      <span>{item?.class_name ? "Class" : "Category"} - {item?.class_name || item?.category_name}</span>
                                    </li>
                                    <span>Class Topic - {item?.class_topic || "Not available"}</span>
                                    <li>
                                      {/* <span>Start Time - {tConvert(item?.start_time)}</span>
                                        <span>End Time - {tConvert(item?.end_time)}</span> */}
                                      <span>Start Time - <br /> {splitTime[1]}</span>
                                      <span>End Time - <br /> {splitEndTime[1]}</span>
                                    </li>
                                    <li>
                                      {item?.cancel_status == 1 ? <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Cancelled form your side</Tooltip>}>
                                        <span className="d-inline-block">
                                          <strong style={{ color: '#e30808' }}>
                                            Cancelled <MdOutlineInfo />
                                          </strong>
                                        </span>
                                      </OverlayTrigger> : ""}
                                    </li>
                                  </ul>
                                  <Row>
                                    <Col>
                                      <Button className='btn'
                                        disabled={item?.cancel_status == 1 ? true : false}
                                        onClick={() => {
                                          handleShow3();
                                          setPdf(item.teacher_assignment_pdf);
                                          setId(item?.teacher_assignment_pdf[0]?.id);
                                          setEnroll_id(item?.enroll_id);
                                          setTeacher_id(item?.teacher_id);
                                          setStudent_enroll_schedule_id(item?.student_enroll_schedule_id);
                                        }}>Homework</Button>
                                    </Col>
                                    <Col>
                                      <Button className='btn mb-0'
                                        disabled={item?.feedback_status == 1 || item?.cancel_status == 1 ? true : false}
                                        onClick={() => {
                                          handleShow2();
                                          setFeedback({
                                            student_apply_for_demo_id: null,
                                            teacher_id: item?.teacher_id,
                                            enroll_id: item?.student_enroll_schedule_id
                                          })
                                        }
                                        }>Feedback
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </SwiperSlide>

                  </Swiper>
                </div>

                <div className={`${cx.swiperSelect_pillBox}`}>
                  <Swiper
                    initialSlide={location?.state?.index}
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                    onSlideChange={handleSlideChange}
                  >
                    <SwiperSlide>
                      <div className={`${cx.swiperSelect_pill_btn}`}
                        onClick={() => {
                          setloaderStatus(<ReactLoading type="cylon" color="blue" />)
                          setData([])
                          upcomingClasses()
                        }}>Upcoming
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={`${cx.swiperSelect_pill_btn}`}
                        onClick={() => {
                          setloaderStatus(<ReactLoading type="cylon" color="blue" />)
                          setData([])
                          demoClasses()
                        }}>Demo
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={`${cx.swiperSelect_pill_btn}`}
                        onClick={() => {
                          setloaderStatus(<ReactLoading type="cylon" color="blue" />)
                          setData([])
                          completeClasses("Classes")
                        }}>Completed
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
          <div className={`${st.responsive_bottom_height}`}></div>
        </div>
        <div>d</div>
        <Bottombar />

      </section>

      <Footer />

      {/* ---------Contact Support---------- */}

      <div className={`${cx.popupBox} ${show && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody} ${cx.popupBoxHeight}`}>
          <div className={`${cx.popupTitle}`}>
            <h5>Contact Support
              <i className={`${cx.closeIcon}`} onClick={handleClose}>
                <MdOutlineClose />
              </i>
            </h5>
          </div>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <Form onSubmit={handleSubmit(contactSupport)} className={`${st.formFull}`} >
                  <Form.Group className={`${st.formField}`} controlId="">
                    <Form.Control as="textarea" value={contactSupports.message} placeholder="Message" style={{ height: "100px" }}
                      {...register("contactSupports", {
                        required: "This field is required",
                      })}
                      onChange={(e: any) => {
                        setContactSupports((prev: any) => {
                          return { ...prev, message: e.target.value }
                        })
                      }}
                    />
                    {errors?.contactSupports?.type === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </Form.Group>
                  <Col className="col-12">
                    <Button className={`btn mt-2 ${cx.bookBtn}`}
                      type="submit">{loadingSpinner}
                    </Button>
                  </Col>
                  <a style={{ textDecoration: 'none', color: 'black' }} target="_blank" href="https://api.whatsapp.com/send?phone=918440868293">
                    <p className={` ${cx.whatsAppBtn}`}>WhatsApp Support
                      <a target="_blank" href="https://api.whatsapp.com/send?phone=918440868293"><IoLogoWhatsapp /></a>
                    </p>
                  </a>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* ---------Rate you experience---------- */}

      <div
        className={`${cx.popupBox} ${show2 && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody} ${cx.popupBoxHeight} ${cx.popupFeedBack}`}>

          <div className={`${cx.popupTitle}`}>
            <h5>Rate your Experience
              <i className={`${cx.closeIcon}`} onClick={handleClose2}>
                <MdOutlineClose />
              </i>
            </h5>
          </div>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <Form.Group className={`${st.formField}`} controlId="">
                  {/* <Form.Label>Rate your Experience</Form.Label> */}
                  <ul className={`${cx.ratingStar} d-flex`}>
                    {stars?.map(
                      (item: any, num: number) => {
                        return (
                          <li>
                            {item?.point === 0 ? (
                              <BsStar
                                onClick={() => {
                                  RatingStar(0.5, num);
                                }}
                              />
                            ) : item?.point === 0.5 ? (
                              <BsStarHalf
                                onClick={() => {
                                  RatingStar(1, num);
                                }}
                              />
                            ) : (
                              <BsStarFill
                                onClick={() => {
                                  setFeedback(item)
                                  RatingStar(0, num);
                                }}
                              />
                            )}
                          </li>
                        );
                      }
                    )}
                  </ul>

                </Form.Group>
                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control
                    as="textarea"
                    value={feedback.message}
                    placeholder="Message"
                    onChange={(e: any) => {
                      setFeedback((prev: any) => {
                        return { ...prev, message: e.target.value }
                      })
                    }}
                    style={{ height: "100px" }}
                  />
                </Form.Group>
              </Col>
              <Col className="col-12">
                <Button className={`btn mt-2 ${cx.bookBtn}`}
                  onClick={demoClsFeedback}
                >{loadingSpinner}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* SUBMIT DOWNLOAD HOMEWORK POPUP */}
      <div
        className={`${cx.popupBox} ${cx.popupBtnTwo} ${show3 && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody} ${cx.buttonTwo}`}>
          <div className={`${cx.popupTitle} ${cx.buttonTo}`}>
            <h5>
              Homework
              <i className={`${cx.closeIcon}`} onClick={handleClose3}>
                <MdOutlineClose />
              </i>
            </h5>
          </div>
          <div className={`${cx.cotentBody} ${cx.buttonTT}`}>
            <Row>
              <Col md={6} className={`${cx.fileBtn}`}>
                <NavLink to="#" className={`btn mt-2 ${cx.bookBtn}`}>
                  Submit Homework
                </NavLink>
                <input type="file" onChange={submitHomework} />
              </Col>
              <Col md={6}>
                {/* {teacher_assignment_pdf == null ? notify("Hello") : <a type="button" className={`btn mt-2 ${cx.bookBtn}`} href={url + teacher_assignment_pdf}>Download Homework</a>} */}
                <NavLink to="#" className={`btn mt-2 ${cx.bookBtn}`}
                  onClick={() => {
                    handleClose3()
                    handleShow5()
                  }}>
                  Download Homework
                </NavLink>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* HOMEWORK */}
      <div className={`${cx.popupBoxmodule} ${show5 && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody}`}>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <div className={`${cx.homeworkHeading}`}>
                  <h3>Homework</h3>
                  <i className={`${cx.closeIcon}`} onClick={handleClose5}>
                    <MdOutlineClose />
                  </i>
                </div>
                {pdf.map((item: any) => {
                  let homeWorkLink = item?.teacher_assignment_pdf
                  let homeWorkDate = item?.created_at?.split('T')
                  let homework = item?.teacher_assignment_pdf?.split('/');
                  return (
                    <div className={`${cx.homeworkScroll}`}>
                      <div className={`${cx.homeworkDownload}`}>
                        <span>
                          <h6 className={`${cx.homework_Pdf}`}>{homework[3]?.slice(0, 10)}</h6>
                          <h6 className={`${cx.homework_date}`}>Date-{homeWorkDate[0]}</h6>
                        </span>
                        <a href={url + homeWorkLink} download>
                          <AiFillEye /> </a>
                      </div>
                    </div>
                  )
                })}
                {pdf.length === 0 && <h4>No homework</h4>}
              </Col>
            </Row>
          </div>
        </div>
      </div >

      {/* DEMO CLASS CANCEL */}
      <div className={`${cx.popupBox} ${show7 && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody}`}>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <h5>Do you want to Cancel this class?</h5>
              </Col>
              <Col className={`col-12 ${cx.marginMin}`}>
                <NavLink
                  to="#"
                  className={`btn border-end ${cx.actionBtn}`}
                  onClick={handleClose7}>
                  NO
                </NavLink>
                <Button
                  className={`btn ${cx.actionBtn}`}
                  onClick={() => {
                    if (checkClass != 400) {
                      upcomingCancelClasses(enroll_id, upcommingENrollID, studentapplyfordemoID, teacher_id, rescheduleTime, date)
                    }
                  }}>YES
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div >

      {/* Low rating popup */}
      < div className={`${cx.popupBoxmodule} ${show6 && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody}`}>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <div className={`${cx.homeworkHeading}`}>
                  <h3>Notification</h3>
                  <i className={`${cx.closeIcon}`} onClick={handleClose6}>
                    <MdOutlineClose />
                  </i>
                </div>
                <div className={`${cx.homeworkScroll}`}>
                  <div className={`${cx.homeworkDownload}`}>
                    <div>
                      <h4>Sorry to hear you did'nt like the demo.</h4>
                      <button
                        onClick={() => {
                          navigate.push("/student/class-details", {
                            userId: localData?.id,
                            category_id: localData?.category_id,
                            subjectName: subjectName,
                            plans: plans,
                            subject_details: subject_details,
                            url: url,
                            classId: classId,
                            subjectId: subjectId,
                            categoryId: categoryId,
                            courseId: courseId,
                            courseDetails: courseDetails,
                          })
                        }}
                        className={`btn ${cx.actionBtn}`}>Click Here..</button>
                      <h6>To get another demo class</h6>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div >

      <TeacherDetails teacherDetails={teacherDetails} show={showTeacherDetails} handleTeacherDetailsClose={handleTeacherDetailsClose} />
      <DemoDetailM teacherDetails={teacherDetails} showDemo={showDemoDetails} handleDemoDetailsClose={handleDemoDetailsClose} />
    </>
  );
}