import React, { useEffect, useState } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Teachingview.module.scss";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Col, Row, Form, InputGroup, Button } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Teacherteachingview(props: any) {
  let localData = JSON.parse(localStorage.getItem("userData")!);

  const location: any = useLocation();

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const navigate = useHistory();
  const [id, setId] = useState("");
  const [fees, setFees] = useState(location?.state?.fees?.details);
  const [categoryDetailsLength, setCategoryDetailsLength] = useState<number>(0);
  const [ClassorCategoryId, setClassorCategoryId] = useState<number>(0);
  const [SubjectorCourseId, setSubjectorCourseId] = useState<number>(0);
  const [classData, setClassData] = useState<any>([]);
  const [subject, setSubject] = useState<any>([]);
  const [course, setCourse] = useState<any>([]);
  const [type, setType] = useState("1");
  const [subjectorcourses, setsubjectorcourses] = useState<any>([]);

  useEffect(() => {
    getallclscategory();
  }, [])
  let formdata = new FormData();

  // ----------------------------- GET ALL CLASS CATEGORY API------------------------------//
  function getallclscategory() {

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/get_all_class_subject_categories_course`,
      headers: {
        "Authorization": "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          let classcategories: any[] = [];
          let subjects: any[] = [];
          let courses: any[] = [];
          response.data.data.categoryDetails?.forEach((subelement: any, index: number) => {
            classcategories.push({ label: subelement?.name, value: subelement?.id, uniqueId: subelement?.id, type: 'categories' })
            subelement?.courseDetails.forEach((course: any) => {
              courses?.push({ label: course?.name, value: course?.id })
            });
          });
          response.data.data?.classDetails?.forEach((subelement: any, index: number) => {
            classcategories.push({ label: subelement?.class_name, value: `${subelement?.id + response.data.data.categoryDetails.length}`, uniqueId: subelement?.id, type: 'class' })
            subelement?.subjectDetails.forEach((subject: any) => {
              subjects?.push({ label: subject?.title, value: subject?.id })
            });
          });
          setCategoryDetailsLength(response.data.data.categoryDetails.length)
          setClassData(classcategories)
          setSubject(subjects);
          setCourse(courses);
          // setTimenotify(response.data.message);
          // setOnetimenotify(true);

        }
        else {
          setTimenotify(response.data.message);
          // setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  // ----------------------------- EDIT TEACHING DETAILS ------------------------------//
  function addTeachingDetails() {

    const token = localStorage.getItem("api_token");
    let formdata = new FormData();
    formdata.append('id', JSON.stringify(location?.state?.state?.details.id));
    formdata.append('class_or_category_id', ClassorCategoryId?.toString());
    formdata.append('subject_or_course_id', SubjectorCourseId?.toString());
    formdata.append('type', type);
    formdata.append('fees', fees);

    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/updateTeachingDetails`,
      headers: {
        "Authorization": "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/teacher/teaching-details',
              state: { detail: response.data.id }
            });
          }, 2000);
          let classcategories: any[] = [];
          let subjects: any[] = [];
          let courses: any[] = [];
          response.data.data.categoryDetails?.forEach((subelement: any, index: number) => {
            classcategories.push({ label: subelement?.name, value: subelement?.id, uniqueId: subelement?.id, type: 'categories' })
            subelement?.courseDetails.forEach((course: any) => {
              courses?.push({ label: course?.name, value: course?.id })
            });
          });
          response.data.data?.classDetails?.forEach((subelement: any, index: number) => {
            classcategories.push({ label: subelement?.class_name, value: `${subelement?.id + response.data.data.categoryDetails.length}`, uniqueId: subelement?.id, type: 'class' })
            subelement?.subjectDetails.forEach((subject: any) => {
              subjects?.push({ label: subject?.title, value: subject?.id })
            });
          });
          setCategoryDetailsLength(response.data.data.categoryDetails.length)
          setClassData(classcategories)
          setSubject(subjects);
          setCourse(courses);
          // localStorage.setItem("api_token", response.data.data.api_token);
          // localStorage.setItem("userData", JSON.stringify(response.data.data));
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  function handleClassCategoryStatus(eventclass: any) {
    setClassorCategoryId(eventclass.uniqueId)
    if (eventclass.type == "categories") {
      setsubjectorcourses(course)
    } else {
      setsubjectorcourses(subject)
    }
  }
  function handleSubjectCoursesStatus(event: any) {
    setSubjectorCourseId(event.value)
  }

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                  <NavLink to="/teacher/teaching-details" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col>
                </Col>
                <Col className="text-end">

                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img src={logo} className={`${cx.logo}`} />
            </Col>
          </div>

          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={'m-auto'}>
              <h5 className={`${st.themeColor}`}>Teaching Details</h5>
              <p></p>
            </Col>
            <Col lg={4} className={'m-auto'}>
              <Form className={`${st.formFull}`}>

                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control
                    key={props?.class_or_category_id}
                    onChange={handleSubjectCoursesStatus}
                    value={location?.state?.state?.details?.class_name ? location?.state?.state?.details?.class_name : location?.state?.state?.details?.category_name}
                  />
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control
                    key={props?.class_or_category_id}
                    onChange={handleSubjectCoursesStatus}
                    value={location?.state?.state?.details?.courses_name ? location?.state?.state?.details?.courses_name : location?.state?.state?.details?.subject_name}
                  />
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control type="number" placeholder={`Fees- ${localData?.currency.slice(4)} `}
                    value={fees}
                    key={props?.fees}
                    onChange={(e) => {
                      setFees(e.target.value)
                    }} />
                </Form.Group>

                <Form.Group className={`${cx.formField}`}>
                  <NavLink to="/teacher/teaching-details" className={`btn ${cx.grayBtn}`}>
                    Cancel
                  </NavLink>
                  <Button className={`btn ${cx.actionBtn}`}
                    onClick={addTeachingDetails}>
                    Save
                  </Button>
                </Form.Group>

              </Form>
            </Col>
          </div>
        </div>
        {/* <Rightbody /> */}
      </section>
    </>
  );
}