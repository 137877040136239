import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    notifyState: 0,
}

const notifyCount: any = createSlice({
    name: "notifyCount",
    initialState,
    reducers: {
        changeNotifyCount(state = initialState, action) {
            // console.log(action, "11111acttiorrnnn")
            state.notifyState = action?.payload
            // state.countState = action?.payload?.length
        },

    },
});
export const { changeNotifyCount } = notifyCount.actions;
export default notifyCount.reducer;