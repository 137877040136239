import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import axios from 'axios';
import cx from "./Course.module.scss";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import 'react-toastify/dist/ReactToastify.css';
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import ReactLoading from "react-loading";

export default function Course() {
  const options2 = {
    loop: false,
    items: 4,
    autoWidth: true,
    dots: false,
    autoplay: false
  };
  const navigate: any = useHistory();
  const location: any = useLocation();

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [data, setData] = useState([]);
  const [classOrCategory, setClassOrCategory] = useState("classes");
  const [image, setImage] = useState("");
  const [userid, setUserid] = useState("");
  const [type, setType] = useState("");
  const [class_id, setClass_id] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [subject, setSubject] = useState<any[]>([]);
  const [courses, setCourses] = useState<any[]>([]);
  const [titleName, setTitleName] = useState("");
  const [imageUrls, setImageUrls] = useState<any>({
    banners_url: "",
    category_images: "",
    class_images: "",
    course_images: "",
    subject_images: ""
  });

  useEffect(() => {
    getCategoryId(location?.state?.type);
    if (location?.state?.type === "classes") {
      setTitleName("Classes")
    }
    else {
      setTitleName("Categories")
    }
  }, [])

  // REMOVE <p> TAG: 
  function removeTags(str: any) {
    if ((str === null) || (str === '') || (str === undefined))
      return false;
    else
      str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  // --------------------------------CLASS/CATEGORY API-----------------------------------//
  function getCategoryId(e: any) {
    let formdata = new FormData();
    formdata.append('userId', userid);
    formdata.append('type', type);
    formdata.append('class_id', class_id);
    formdata.append('category_id', category_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/gethomeScreen`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token")!,
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          setData(response.data.data)
          setSubject(response.data.data[0].classes[0].subject_Details)
          setClassOrCategory(e)
          setImageUrls(() => {
            return {
              banners_url: response.data?.data[0]?.banners_url,
              category_images: response.data?.data[0]?.category_images,
              class_images: response.data?.data[0]?.class_images,
              course_images: response.data?.data[0]?.course_images,
              subject_images: response.data?.data[0]?.subject_images
            }
          })
          setImage(response.data?.data[0]?.subject_images)
        }
        else {
          // setTimenotify(response.data.message);
          // setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/home" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>{titleName}</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${st.spaceBox}`}></div>
          <div className={`container ${cx.contentBody} mt-3`}>
            <div className={`${cx.catHome}`}>
              <div className="item">
              </div>
              <OwlCarousel className="owl-theme" {...options2}>
                {classOrCategory?.length == 0 && (
                  <div className="d-flex justify-content-center">
                  </div>
                )}
                {classOrCategory === "classes" && data?.map((item: any, index: any) => {
                  return item?.classes?.map((e: any) => {
                    return (
                      <div className="item">
                        <NavLink to="#" className={`${cx.catLink}`}
                          onClick={() => {
                            setSubject(e?.subject_Details);
                          }}>
                          <img src={item.class_images + e.image} />
                          <h6>{e.class_name}</h6>
                        </NavLink>
                      </div>
                    );
                  })
                })}
                {classOrCategory === "category" && data?.map((item: any, index: any) => {
                  return item?.category?.map((e: any) => {
                    return (
                      <div className="item">
                        <NavLink to="#" className={`${cx.catLink}`}
                          onClick={() => {
                            setCourses(e?.course_Details);
                          }}>
                          <img src={item.category_images + e.image} />
                          <h6>{e.name}</h6>
                        </NavLink>
                      </div>
                    );
                  })
                })}
              </OwlCarousel>
            </div>

            <Row>
              {/* CLASSES DATA */}
              {classOrCategory?.length == 0 && (
                <div className="d-flex justify-content-center">
                </div>
              )}
              {classOrCategory === "classes" && subject?.map((item: any) => {
                // return item?.classes?.map((e: any) => {
                return (
                  <Col lg={4}>
                    <span
                      onClick={() => {
                        navigate.push("/student/class-details", {
                          ...item, imageUrl: imageUrls?.subject_images
                        })
                      }}
                      className={`${cx.classList}`}>
                      <div className={`${cx.classImg}`}>
                        <img src={`${image}/${item.image}`} />
                      </div>
                      <div className={`${cx.classListBody}`}>
                        <h5>{item?.title}</h5>
                        <p>{removeTags(item?.description)}</p>
                        <p className="text-end"><BsFillArrowRightCircleFill className={`${cx.arrow}`} /></p>
                      </div>
                    </span>
                  </Col>
                );
              })}
              {classOrCategory === "category" && courses?.map((item: any) => {
                // return item?.category?.map((e: any) => {
                return (
                  <Col lg={4}>
                    <span
                      onClick={() => {
                        navigate.push("/student/categorydetails", {
                          ...item, imageUrl: imageUrls?.course_images
                        })
                      }}
                      className={`${cx.classList}`}>
                      <div className={`${cx.classImg}`}>
                        <img src={`${imageUrls?.course_images}/${item.image}`} />
                      </div>
                      <div className={`${cx.classListBody}`}>
                        <h5>{item?.name}</h5>
                        <p>{removeTags(item?.overview)}</p>
                        <p className="text-end"><BsFillArrowRightCircleFill className={`${cx.arrow}`} /></p>
                      </div>
                    </span>
                  </Col>
                );
              })}
            </Row>
          </div>

          <div className={`pt-3`}></div>
        </div>
        {/* <Rightbody /> */}
      </section>
      <Footer />

    </>
  );
}