import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Teachingdetails.module.scss";
import { Col, Row, Form, Button } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../../components/Teacher/Footer/Footer";
import Header from "../../../components/Header/Header";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Teacherteachingdetails() {
  let localData = JSON.parse(localStorage.getItem("userData")!);
  // const selectedDay = (val: any) => {
  // };
  // const navigate = useHistory();
  // const [Category, setCategory] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    teahingDetails();
  }, [])

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

  // ---------------------------GET TEACHING DETAILS API-------------------------- //
  function teahingDetails() {

    let formdata = new FormData();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/getteachingdetails`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        setData(response.data.data)
      })
      .catch(function (error) {
        notify("Something went wrong");
      });
  }

  return (
    <>
      <br />
      <br />
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>

          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/teacher/profile" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Teaching Details</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>

          <div className={`${cx.contentBody}`}>
            <Row>
              <Col lg={12}>
                <div className={`${cx.addButton}`}>
                  <NavLink to="/teacher/add">
                    Add
                  </NavLink>
                </div>
              </Col>
              {data?.length == 0 && (
                <div className="d-flex justify-content-center">
                  {loaderStatus}
                </div>
              )}
              {data?.map((item: any, index: any) => {
                return (
                  <Col lg={4}>
                    <div className={`${cx.classCard}`}>
                      <ul>
                        <li>
                          <span>{item?.class_name ? "Class" : "Category"} Name - {item?.class_name ? item?.class_name : item?.category_name}</span>
                          <span>Fee - {localData?.currency?.slice(4)}{item?.fees}</span>
                        </li>
                        <li>
                          <span>{item?.subject_name ? "Subject" : "Course"} Name - {item?.subject_name ? item?.subject_name : item?.courses_name}</span>
                        </li>
                      </ul>
                      {/* <Button
                        onClick={() => {
                          navigate.push("/teacher/teaching-view", {
                            state: { details: item },
                            fees: { details: item?.fees }
                          })
                        }}>
                        View More
                      </Button> */}
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}