import React, { useState, useEffect } from "react";
import axios from 'axios';
import cx from "./Availability.module.scss";
import st from "../../../style.module.scss";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../../components/Teacher/Footer/Footer";
import Header from "../../../components/Header/Header";
import ReactLoading from "react-loading";
import moment from "moment";
import "moment/locale/es";
import mtimezone from "moment-timezone";
import { datetimeconstant } from "../../../constants/datetime";
import { RotatingLines } from "react-loader-spinner";

export default function Teacheravailability() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const d = new Date();

  const location: any = useLocation();
  let localData = JSON.parse(localStorage.getItem("userData")!);

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  // const [data, setData] = useState<any>([]);
  const [days, setDays] = useState<any>('sun');
  const weekdays: string[] = ['sat', 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
  const weekdate: string[] = ['2023-01-28', '2023-01-29', '2023-01-30', '2023-01-31', '2023-02-01', '2023-02-02', '2023-02-03', '2023-02-04', "2023-02-05"]
  const [singleDay, setSingleDay] = useState<any>([]);
  // const [uid, setUid] = useState<any>("");
  const [userId, setUserId] = useState('');
  const weekdata = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Confirm');
  const [disabledBtn, setDisabledBtn] = useState<any>(false);

  const [daysData, setDaysData] = useState<any>({
    sun: [{ start_time: "", end_time: "", uid: "", date: "2023-01-29" }],
    mon: [{ start_time: "", end_time: "", uid: "", date: "2023-01-30" }],
    tue: [{ start_time: "", end_time: "", uid: "", date: "2023-01-31" }],
    wed: [{ start_time: "", end_time: "", uid: "", date: "2023-02-01" }],
    thu: [{ start_time: "", end_time: "", uid: "", date: "2023-02-02" }],
    fri: [{ start_time: "", end_time: "", uid: "", date: "2023-02-03" }],
    sat: [{ start_time: "", end_time: "", uid: "", date: "2023-02-04" }],
  });
  let [daysData1, setDaysData1] = useState<any>({
    sun: [{ start_time: "", end_time: "", uid: "", date: "2023-01-29" }],
    mon: [{ start_time: "", end_time: "", uid: "", date: "2023-01-30" }],
    tue: [{ start_time: "", end_time: "", uid: "", date: "2023-01-31" }],
    wed: [{ start_time: "", end_time: "", uid: "", date: "2023-02-01" }],
    thu: [{ start_time: "", end_time: "", uid: "", date: "2023-02-02" }],
    fri: [{ start_time: "", end_time: "", uid: "", date: "2023-02-03" }],
    sat: [{ start_time: "", end_time: "", uid: "", date: "2023-02-04" }],
  });

  useEffect(() => {
    let manipulateddateobj: any = datetimeconstant;
    let curr = new Date;
    for (let i = 0; i <= 8; i++) {
      let lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + i));
      let date = lastday.toString()
      let weekday = date.slice(0, 3).toLocaleLowerCase();
      for (const [key, value] of Object.entries(datetimeconstant)) {
        if (key === weekday) {
          manipulateddateobj[weekday][0]["date"] = lastday.toISOString();
        }
      }
    }
    setDaysData(manipulateddateobj)
  }, [])

  const [teacher_available_slot_status] = useState(1);
  const [arrStore, setArrStore] = useState<any[]>([]);

  useEffect(() => {
    let arr: any[] = []
    singleDay?.map((item: any, index: any) => {
      if (days == "sun" && item?.day === 1) {
        arr = item?.teacher_availabilty_of_slots_details;
      } else if (days == "mon" && item?.day === 2) {
        arr = item?.teacher_availabilty_of_slots_details;
      } else if (days == "tue" && item?.day === 3) {
        arr = item?.teacher_availabilty_of_slots_details;
      } else if (days == "wed" && item?.day === 4) {
        arr = item?.teacher_availabilty_of_slots_details;
      } else if (days == "thu" && item?.day === 5) {
        arr = item?.teacher_availabilty_of_slots_details;
      } else if (days == "fri" && item?.day === 6) {
        arr = item?.teacher_availabilty_of_slots_details;
      } else if (days == "sat" && item?.day === 7) {
        arr = item?.teacher_availabilty_of_slots_details;
      }
    })
    setArrStore(arr)
  }, [days, singleDay])

  useEffect(() => {
    setArrStore(arrStore)
  }, [arrStore])

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const navigate = useHistory();
  const [lastTime, setLastTime] = useState("");
  const [checking, setChecking] = useState(1)
  // const [checkDay, setCheckDay] = useState("")
  const handleDateRange = (obj: any) => {
  }
  let newdatachgtimezone = ''
  const handleTimeData = (event: any, index: any) => {
    // const dateStart = moment.utc(event);
    moment().locale(localData?.timezone || location?.state?.timezone);
    const datechangebasedontime = mtimezone.tz(`${daysData[days][index]?.date} ${event}`, localData?.timezone || location?.state?.timezone).utc().format();
    let modifiedStarttime = datechangebasedontime?.split("T")[1]?.slice(0, 5);
    // let modifieddate = datechangebasedontime?.split("T")[0] + "T" + modifiedStarttime + ":00.000Z";
    let checkday = weekdate.indexOf(datechangebasedontime?.split("T")[0])
    switch (checkday) {
      case -1:
        break;
      case checkday:
        newdatachgtimezone = weekdays[checkday]
        // setCheckDay(newdatachgtimezone)
        setChecking(checking + 1)
        break;
    }
    // console.log(newdatachgtimezone, event, "newdatachgtimezone34")
    let e = event;
    let hours = e.slice(0, 2);
    let afterHours = e.slice(2);
    let time = +hours + 1;
    let fullEndTime =
      time > 23 ? `0${24 - time}${afterHours}` : time < 10 ? `0${time}${afterHours}` : `${time}${afterHours}`;
    let uidDate = Date.now();
    setLastTime(fullEndTime);
    setDaysData((prev: any) => {
      prev[days][index].start_time = e;
      prev[days][index].end_time = fullEndTime;
      prev[days][index].uid = uidDate
      return prev;
    });
    let e1 = modifiedStarttime;
    let hours1 = e1.slice(0, 2);
    let afterHours1 = e1.slice(2);
    let time1 = +hours1 + 1;
    let date = datechangebasedontime.slice(0, 10)
    let fullEndTime1 =
      time1 > 23 ? `0${24 - time1}${afterHours1}` : time1 < 10 ? `0${time1}${afterHours1}` : `${time1}${afterHours1}`;
    let length = daysData1[newdatachgtimezone]?.length - 1

    // setDaysData1((prev: any) => {
    //   for (const [weekday] of Object.entries(prev)) {
    //     if (weekday === newdatachgtimezone) {
    //       prev[newdatachgtimezone]?.push({ start_time: "", end_time: "", uid: "", date: "" })
    //     }
    //   }
    //   let data: any // Instead of const data;

    //   // Now, when updating the state
    //   if (!data) {
    //     data = {};
    //   }

    //   if (!data[newdatachgtimezone]) {
    //     // If data[newdatachgtimezone] is undefined, create it as an array
    //     data[newdatachgtimezone] = [];
    //   }

    //   const length = data?.[newdatachgtimezone]?.length;

    //   if (!data?.[newdatachgtimezone]?.[length]) {
    //     // If data[newdatachgtimezone][length] is undefined, create it as an object
    //     data[newdatachgtimezone][length] = {};
    //   }


    //   if (!prev) {
    //     // If prev is undefined, create it as an object
    //     prev = {};
    //   }

    //   if (!prev[newdatachgtimezone]) {
    //     // If prev[newdatachgtimezone] is undefined, create it as an object
    //     prev[newdatachgtimezone] = {};
    //   }

    //   if (!prev?.[newdatachgtimezone]?.[length]) {
    //     // If prev[newdatachgtimezone][length] is undefined, create it as an object
    //     prev[newdatachgtimezone][length] = {};
    //   }

    //   prev[newdatachgtimezone][length].start_time = e1;
    //   prev[newdatachgtimezone][length].end_time = fullEndTime1;
    //   prev[newdatachgtimezone][length].uid = uidDate;
    //   prev[newdatachgtimezone][length].date = date;
    //   return prev;
    // });

    setDaysData1((prev: any) => {
      // Check if newdatachgtimezone exists in prev
      if (!prev[newdatachgtimezone]) {
        // If not, initialize it as an array
        prev[newdatachgtimezone] = [];
      }

      // Add an empty object to the array
      prev[newdatachgtimezone].push({ start_time: "", end_time: "", uid: "", date: "" });

      // Now, when updating the state
      const length = prev[newdatachgtimezone].length - 1; // Use length - 1 as the index

      prev[newdatachgtimezone][length].start_time = e1;
      prev[newdatachgtimezone][length].end_time = fullEndTime1;
      prev[newdatachgtimezone][length].uid = uidDate;
      prev[newdatachgtimezone][length].date = date;

      return { ...prev }; // Make sure to return a new object to trigger a state update
    });

  };


  useEffect(() => {
    getTeacherAvailable();
  }, [])

  const [showCheckboxButton, setshowCheckboxButton] = useState(false);
  const checkboxButtonShow = () => {
    setshowCheckboxButton(!showCheckboxButton);
  };

  //------------------------- ADD AVAIBILTY API --------------------------//
  function teacherAvailable() {
    let localdatatime: any[] = []
    for (const [localweek, localweekVal] of Object?.entries(daysData)) {
      // console.log(localweekVal, "localweek")
      if (Array?.isArray?.(localweekVal) && localweekVal?.length === 1) localdatatime?.push({ [localweek]: localweekVal })
      if (Array?.isArray?.(localweekVal) && localweekVal?.length > 1) localdatatime?.push({ [localweek]: localweekVal?.filter((item: any) => item?.start_time !== "") })
    }

    let utcconvertdatatime = []
    for (const [weekday, weekdayVal] of Object?.entries(daysData1)) {
      if (Array.isArray(weekdayVal) && weekdayVal.length >= 1) {
        utcconvertdatatime.push({
          [weekday]: weekdayVal.filter((utcitem: any) => {
            if (utcitem.uid === '' && weekdayVal.length === 1) return true
            else {
              const checkItem: any = Object.values(daysData).flat().find((item: any) => item.uid === utcitem.uid)
              if (checkItem && checkItem.uid && checkItem.start_time.length == 5 && checkItem.end_time.length == 5) {
                return true
              } else {
                return false
              }
            }
          })
        })
      }
    }

    // let utcconvertdatatime: any[] = [];

    // for (const [weekday, weekdayVal] of Object.entries(daysData1)) {
    //   if (Array.isArray(weekdayVal) && weekdayVal.length >= 1) {
    //     utcconvertdatatime.push({
    //       [weekday]: weekdayVal.filter((utcitem: any) => {

    //         if (utcitem.uid === '' && weekdayVal.length === 1) {
    //           return true;
    //         } else {
    //           const checkItem: any = Object.values(daysData).flat().find((item: any) => item.uid === utcitem.uid);

    //           if (checkItem && checkItem.uid) {
    //             // Check for valid start_time and end_time
    //             if (
    //               checkItem.start_time && checkItem.start_time.length === 5 &&
    //               checkItem.end_time && checkItem.end_time.length === 5
    //             ) {
    //               return true;
    //             }
    //           }

    //           return false;
    //         }
    //       })
    //     });
    //   }
    // }

    let localRightTime: any = localdatatime.reduce((a, v) => {
      let localkey = Object.keys(v)
      let localvalue = Object.values(v)
      return ({ ...a, [localkey[0]]: localvalue[0] })
    }, {})


    let rightTime: any = utcconvertdatatime.reduce((a, v) => {
      let localkey = Object.keys(v)
      let localvalue: any[] = Object.values(v)
      if (localvalue[0].length === 0) localvalue[0].push({ start_time: "", end_time: "", uid: "", date: "" })
      return ({ ...a, [localkey[0]]: localvalue[0] })
    }, {})



    // let rightTime: any = utcconvertdatatime.reduce((a, v) => {
    //   console.log(a, v, 'reduce')
    //   let localkey = Object.keys(v);
    //   let localvalue: any[] = Object.values(v);

    //   console.log(localvalue, 'localvalyee111e');

    //   if (localvalue[0].length === 0) {
    //     localvalue[0].push({ start_time: "", end_time: "", uid: "", date: "" });
    //   }

    //   return ({ ...a, [localkey[0]]: localvalue[0] });
    // }, {});

    let formdata = new FormData();
    formdata.append('userId', localData?.id || location?.state?.id || location?.state?.detail || location?.state?.userId);
    formdata.append('data', JSON.stringify(localRightTime));
    formdata.append('data1', JSON.stringify(rightTime));
    formdata.append('teacher_available_slot_status', teacher_available_slot_status.toString());

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/signup-teacher-AvailabiltyOfSlots`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    setDisabledBtn(true);
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        // setData?.(response?.data?.data);
        if (response.data.statusCode == 200) {
          // localStorage.setItem("api_token", response.data.data.api_token);
          {
            location?.state?.detail ? setTimenotify("Calendar updated. Details are pending for admin verification. You will be notified once it is approved. Please stay tuned for further updates.") : setTimenotify(response.data.message)
          }
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/teacher/login',
              state: { detail: response.data.id }
            });
          }, 2000);
        }
        else {
          setDisabledBtn(false);
          setLoadingSpinner('Confirm')
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        notify("Something went wrong, Please try again later");
      });
  }

  const [matchData, setMatchData] = useState<any>([])

  //------------------------- GET TEACHER AVAIBILTY API --------------------------//
  function getTeacherAvailable() {

    setDaysData({
      sun: [{ start_time: "", end_time: "", uid: "", date: "2023-01-29" }],
      mon: [{ start_time: "", end_time: "", uid: "", date: "2023-01-30" }],
      tue: [{ start_time: "", end_time: "", uid: "", date: "2023-01-31" }],
      wed: [{ start_time: "", end_time: "", uid: "", date: "2023-02-01" }],
      thu: [{ start_time: "", end_time: "", uid: "", date: "2023-02-02" }],
      fri: [{ start_time: "", end_time: "", uid: "", date: "2023-02-03" }],
      sat: [{ start_time: "", end_time: "", uid: "", date: "2023-02-04" }],
    })

    let formdata = new FormData();
    formdata.append('uid', localData?.id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher-get_AvailabiltyOfSlots`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        setSingleDay(response?.data?.data);
        setMatchData(response?.data?.data);
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        // setData?.(response?.data?.data);
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  //------------------------- DELETE TEACHER AVAIBILTY API --------------------------//
  function deleteTeacherAvailabilty(userId: any) {

    let formdata = new FormData();
    formdata.append('uid', userId);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/Delete_avaiabletime_slot`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        // setData?.(response?.data?.data);
        setSingleDay(response?.data?.data);
        handleClose();
        getTeacherAvailable();
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  const increase = () => {
    setDaysData((prev: any) => {
      return {
        ...prev,
        [days]: [
          daysData[days].flat(),
          { start_time: "", end_time: "", date: daysData[days][0].date },
        ].flat(),
      };
    })
  }

  const elseIncrese = () => {
    setDaysData((prev: any) => {
      return {
        ...prev,
        [days]: daysData[days],
      };
    });
  }

  const [selectedDay, setSelectedDay] = useState('sun'); // To track which day is selected

  const handleDayClick = (day: any) => {
    setSelectedDay(day); // Update the state when a day is clicked
  };

  // Filter the data based on the selected day

  const filteredData = matchData?.filter((item: any) =>
    item?.converttime?.some((event: any) => event?.day?.toLowerCase()?.trim() === selectedDay?.toLowerCase()?.trim())
  );

  return (
    <>
      <ToastContainer limit={1} />
      {location?.pathname === "/teacher/pavailability" ?? <Header />}
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Mark Your availabilty</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>
          <Col className={`col-8 m-auto ${cx.availabilityText}`}>
            <h6>Mark Your availabilty</h6>
          </Col>

          {/* {
            ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => (
              <button key={day} onClick={() => handleDayClick(day)}>
                {day.toUpperCase()}
              </button>
            ))
          } */}

          <ul className={`container ${cx.weekList}`}>
            {Object.keys(daysData).map((day, index): any => {
              return (
                <li key={index}>
                  <button
                    className={`${days === day ? `${cx.active}` : ""} ${cx.weekList}`}
                    onClick={() => {
                      setDays(day);
                      handleDateRange(Object.values(daysData)[index]);
                      handleDayClick(day)
                    }}>
                    {day}
                  </button>
                </li>
              );
            })}
          </ul>

          <div className={`container ${cx.contentBody}`}>
            <Row className={`${cx.labelTitleRow}`}>
              <Col className={`col-5 ${cx.labelTitle}`}>Start Time</Col>
              <Col className={`col-5 ${cx.labelTitle}`}>End Time</Col>
              <Col className={`col-2 ${cx.labelTitle}`}>Action</Col>
            </Row>
            {/* GETTING DATA */}
            {filteredData?.map((item: any, index: number) => {
              return (
                item?.converttime?.map((event: any) => {
                  if (event.day.toLowerCase() === selectedDay.toLowerCase()) {
                    return (
                      <>
                        {
                          <Row className={`${cx.addedFieldRows}`} key={index}>
                            <Col className={`col-5 ${cx.addedField}`}>
                              <input
                                type="time"
                                name="start_time"
                                value={event?.start_time}
                                className={`form-control`}
                              // onChange={(event: any) => {
                              //   handleTimeData(event, index);
                              // }}
                              />
                            </Col>
                            <Col className={`col-5 ${cx.addedField}`}>
                              <input
                                type="time"
                                name="end_time"
                                disabled
                                className={`form-control`}
                                value={event?.end_time}
                              //   ? lastTime
                              //   : daysData[days][index].end_time}
                              />
                            </Col>
                            <Col className={`col-2 ${cx.addedField}`}>
                              {index == 0 ? (
                                <button
                                  className="btn"
                                  onClick={() => {
                                    handleShow();
                                    setUserId(event?.uid)
                                    const list = daysData1[days];
                                    list.splice(index, 1);
                                    setDaysData1((prev: any) => {
                                      return { ...prev, [days]: list };
                                    });
                                  }}>-</button>
                              ) : (
                                <button
                                  className="btn"
                                  onClick={() => {
                                    handleShow();
                                    setUserId(event?.uid)
                                    const list = daysData1[days];
                                    list.splice(index, 1);
                                    setDaysData1((prev: any) => {
                                      return { ...prev, [days]: list };
                                    });
                                  }}>-</button>
                              )}
                            </Col>
                          </Row>
                        }
                      </>
                    );
                  }
                })
              )
              // console.log(arrStore, "aaaaaaaaaaaaawe456789")
              // console.log(item, "itemend_ISTend_IST")
              // let localTimeStart = new Date(`${item?.select_date} ${item?.utc_select_time} UTC`).toLocaleString("en-US", {
              //   timeZone: localData?.timezone,
              //   hour12: false
              // });
              // console.log(localTimeStart, "localTimeStart")
              // let splitStartTime = localTimeStart?.split(",")[1]
              // let localTimeEnd = new Date(`${item?.select_date} ${item?.utc_select_end_time} UTC`).toLocaleString("en-US", {
              //   timeZone: localData?.timezone,
              //   hour12: false
              // });
              // let splitEndTime = localTimeEnd?.split(",")[1];

            })}
            {/* ADDING DATA */}
            {daysData[days]?.map((item: any, index: number) => {
              return (
                <>
                  <Row className={`${cx.addedFieldRows}`} key={index}>
                    <Col className={`col-5 ${cx.addedField}`}>
                      <input
                        type="time"
                        name="start_time"
                        value={daysData[days][index].start_time ? daysData[days][index].start_time : ""}
                        onChange={(event: any) => {
                          handleTimeData(event.target.value, index);
                        }}
                        // onBlur={(event: any) => {
                        //   handleTimeData(event.target.value, index);
                        // }}
                        className={`form-control`} />
                    </Col>
                    <Col className={`col-5 ${cx.addedField}`}>
                      <input
                        type="time"
                        name="end_time"
                        disabled
                        className={`form-control`}
                        value={daysData[days][index].end_time === lastTime ? lastTime : daysData[days][index].end_time} />
                    </Col>
                    <Col className={`col-2 ${cx.addedField}`}>
                      {index === 0 ? (
                        <button
                          className="btn"
                          onClick={() => {
                            const idx = daysData[days].length - 1;
                            daysData[days][idx].start_time ? increase() : elseIncrese()
                          }}>+</button>
                      ) : (
                        <button
                          className="btn"
                          onClick={() => {
                            const list = daysData[days];
                            list.splice(index, 1);
                            setDaysData((prev: any) => {
                              return { ...prev, [days]: list };
                            });
                            // setDaysData1((prev: any) => {
                            //   return { ...prev, [days]: list };
                            // });
                          }}>-</button>
                      )}
                    </Col>
                  </Row>
                </>
              );
            })}

            <Row className={`container ${cx.forwardBackwardbtn}`}>
              <button
                onClick={(day: any) => {
                  const index = weekdata.indexOf(days)
                  // console.log(index, "qeioeqpilk")
                  // console.log(weekdata[index - 1], "qert334")
                  if (index == 0) {
                    setDays(weekdata[index])
                  } else {
                    setDays(weekdata[index - 1])
                  }
                }}>Previous</button>
              <button
                onClick={(day: any) => {
                  const index = weekdata.indexOf(days)
                  if (index == 6) {
                    setDays(weekdata[index])
                  } else {
                    setDays(weekdata[index + 1])
                  }
                }}>Next</button>
            </Row>

            <Form.Group className={`mt-4 ${st.formField}`}>
              <Col lg={12}>
                <label className={`${cx.confirmCheckbox}`}>
                  <input type="checkbox" onClick={checkboxButtonShow} />
                  The following box needs to be checked once you have filled out your dates of availability.
                </label>
              </Col>
              {showCheckboxButton && <Col lg={12} className={`text-center`}>
                <Button
                  className={`btn ${st.actionBtn}`}
                  disabled={disabledBtn}
                  onClick={() => {
                    teacherAvailable()
                  }}>{loadingSpinner}
                </Button>
              </Col>}
            </Form.Group>
          </div>

          <div className={`pt-3 ${st.spaceBox}`}></div>
        </div>
      </section >
      <Footer />

      {/* DELETE POPUP */}
      <div
        className={`${cx.popupBox} ${show && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody}`}>

          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <h5>Do you want to Delete?</h5>
              </Col>
              <Col className="col-12">
                <NavLink to="#" className={`btn border-end ${cx.actionBtn}`} onClick={handleClose}>
                  NO
                </NavLink>
                <Button className={`btn ${cx.actionBtn}`}
                  onClick={() => {
                    deleteTeacherAvailabilty(userId);
                  }}>
                  YES
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
} 